import React, { Component, useState, useRef, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  FieldRadioButton,
  SecondaryButton,
} from '../../components';
import { zoomLogo } from './zoomLogo';
import { BroadcastChannel } from 'broadcast-channel';
import CustomPlatformSelectField from './CustomPlatformSelectField';

import css from './EditListingLocationOnlineForm.module.css';

const preventDefault = e => {
  e.preventDefault();
};

// const hashLink = 'http://localhost:4001';
// const hashLink = process.env.redirectURL;
const hashLink = 'https://zoom.learnactive.ca';
var currentUserEmail;

// Connection to a broadcast channel
const bc = new BroadcastChannel('test_channel');

export const EditListingLocationOnlineFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        certificateOptions,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        isConnected,
        userEmail,
        identity,
        values,
        listingId,
        required,
        returnUrl,
        id = 1,
      } = formRenderProps;

      const showAsRequired = pristine && required;

      currentUserEmail = userEmail;
      // console.log(listingId);  listingId is actually the listing author's ID
      var disconnectLink =
        hashLink +
        '/deauthorize?email=' +
        currentUserEmail +
        '&returnUrl=' +
        returnUrl +
        '&id=' +
        listingId;
      const connectLink =
        hashLink + '?returnUrl=' + returnUrl + '&email=' + currentUserEmail + '&id=' + listingId;
      // Online platform connection state

      const [connected, setConnected] = useState(isConnected || false);
      // const [connected, setConnected] = useState(localStorage.getItem('connected') || false);
      // console.log('connected', connected);
      const [error, setError] = useState(null);
      const handleError = () => {
        if (error) {
          return (
            <div style={{ color: 'red' }}>Error: Connection unsuccessful. Please try again.</div>
          );
        }
      };

      bc.onmessage = event => {
        // console.log(event);
      };
      // local storage state monitoring connection success state
      // const [connectionSuccess, setConnectionSuccess] = useState(false);
      // console.log('connectionSuccess', localStorage.getItem('connected'));

      var areTheyConnected;
      const connectedRef = useRef();
      useEffect(() => {
        areTheyConnected = connected;
        // console.log('connection state: ' + areTheyConnected);
        // }, connectionSuccess)

        // location.reload();
        // console.log('useEffect: Connection status is now ' + areTheyConnected);
      }, [connected]);

      //     useEffect(() => {
      //   console.log("useEffect local storage");
      //   const persistedConnected = localStorage.getItem("connected");
      //   setConnectionSuccess(persistedConnected || []);
      //   console.log("connection state: " + connectionSuccess);
      // }, connectionSuccess);
      class InteractiveButton extends Component {
        constructor(props) {
          super(props);
          this.inProgressTimeoutId = null;
          this.readyTimeoutId = null;
          this.state = {
            inProgress: false,
            disabled: connected,
            ready: false,
          };
        }

        componentWillUnmount() {
          window.clearTimeout(this.inProgressTimeoutId);
          window.clearTimeout(this.readyTimeoutId);
        }
        render() {
          const handleClick = () => {
            window.clearTimeout(this.inProgressTimeoutId);
            window.clearTimeout(this.readyTimeoutId);

            this.setState({ inProgress: true, disabled: true });
            var win = window.open(connectLink, 'width=600,height=600');
            win;
            if (win.closed) {
              setConnected(True);
            }
            this.inProgressTimeoutId = window.setTimeout(() => {
              this.setState({ inProgress: false, disabled: false, ready: true });

              // Create a new entry for test.json and store the newly created response.

              // this.readyTimeoutId = window.setTimeout(() => {
              //   this.setState({ inProgress: false, disabled: false, ready: false });
              // }, 2000);
              // setConnected(true);
              this.disabledTimeoutId = window.setTimeout(() => {
                this.setState({ inProgress: false, disabled: true, ready: false });
              }, 2000);
            }, 6000);
            // window.localStorage.getItem('connected');
            // window.onstorage = () => {
            //   // When local storage changes, dump the list to
            //   // the console.
            //   // console.log(JSON.parse(window.localStorage.getItem('connected')));
            // };
          };

          return (
            <>
              <SecondaryButton
                {...this.state}
                onClick={handleClick}
                className={css.connectButton}
                href={connectLink}
                name="location"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2310_12916)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0C15.5225 0 20 4.4775 20 10ZM5 13.5H12.5V8C12.5 7.80302 12.4612 7.60796 12.3858 7.42597C12.3104 7.24399 12.1999 7.07863 12.0607 6.93934C11.9214 6.80005 11.756 6.68956 11.574 6.61418C11.392 6.5388 11.197 6.5 11 6.5H3.5V12C3.5 12.3978 3.65804 12.7794 3.93934 13.0607C4.07863 13.1999 4.24399 13.3104 4.42597 13.3858C4.60796 13.4612 4.80302 13.5 5 13.5ZM13.5 11.5L16.5 13.5V6.5L13.5 8.5V11.5Z"
                      fill="#529DFE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2310_12916">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Connect with Zoom</span>
              </SecondaryButton>
            </>
          );
        }
      }
      class DisconnectButton extends Component {
        constructor(props) {
          super(props);
          this.inProgressTimeoutId = null;
          this.readyTimeoutId = null;
          this.state = {
            inProgress: false,
            disabled: !connected,
            ready: false,
          };
        }

        componentWillUnmount() {
          window.clearTimeout(this.inProgressTimeoutId);
          window.clearTimeout(this.readyTimeoutId);
        }
        render() {
          const handleClickDisconnect = () => {
            window.clearTimeout(this.inProgressTimeoutId);
            window.clearTimeout(this.readyTimeoutId);

            this.setState({ inProgress: true, disabled: true });
            window.open(disconnectLink, '_self');

            this.inProgressTimeoutId = window.setTimeout(() => {
              this.setState({ inProgress: false, disabled: false, ready: true });

              // Create a new entry for test.json and store the newly created response.

              // this.readyTimeoutId = window.setTimeout(() => {
              //   this.setState({ inProgress: false, disabled: false, ready: false });
              // }, 2000);
              // setConnected(true);
              this.disabledTimeoutId = window.setTimeout(() => {
                this.setState({ inProgress: false, disabled: true, ready: false });
              }, 2000);
            }, 6000);
            window.localStorage.getItem('connected');
            window.onstorage = () => {
              // When local storage changes, dump the list to
              // the console.
              // console.log(JSON.parse(window.localStorage.getItem('connected')));
            };
          };

          return (
            <>
              <SecondaryButton
                {...this.state}
                onClick={handleClickDisconnect}
                className={css.disconnectButton}
                href={disconnectLink}
                name="location2"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2310_12916)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0C15.5225 0 20 4.4775 20 10ZM5 13.5H12.5V8C12.5 7.80302 12.4612 7.60796 12.3858 7.42597C12.3104 7.24399 12.1999 7.07863 12.0607 6.93934C11.9214 6.80005 11.756 6.68956 11.574 6.61418C11.392 6.5388 11.197 6.5 11 6.5H3.5V12C3.5 12.3978 3.65804 12.7794 3.93934 13.0607C4.07863 13.1999 4.24399 13.3104 4.42597 13.3858C4.60796 13.4612 4.80302 13.5 5 13.5ZM13.5 11.5L16.5 13.5V6.5L13.5 8.5V11.5Z"
                      fill="#529DFE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2310_12916">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Disconnect Zoom</span>
              </SecondaryButton>
            </>
          );
        }
      }

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} id="#online" onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <h3 className={css.title}>Let's get you connected</h3>
          <h4 className={css.subtitle}>
            Booked classes will be synced with your zoom account automatically once connected.{' '}
          </h4>
          <div className={css.buttonCol}>
            <InteractiveButton />
            <DisconnectButton />
          </div>
          {handleError()}
          <br />
          <p className={css.warning}>
            When classes are booked, whether it's private or group,
            <b>
              {' '}
              the link will be automatically generated, scheduled and shared with you and any
              participants you have approved.
            </b>
          </p>
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <FieldTextInput
            className={css.building}
            type="text"
            name="buildingForOnline"
            id="buildingForOnline"
            label={buildingMessage}
            placeholder={buildingPlaceholderMessage}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            // disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationOnlineFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationOnlineFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationOnlineFormComponent);
