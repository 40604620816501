import React from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { Ottawa } from '../../components';

export const OttawaContainerComponent = props => {
  const {
   
    currentSearchParams,
    history,
    hasGenericError,
    location,
    ...rest
  } = props;

  return (
    <Ottawa
      currentSearchParams={currentSearchParams}
      history={history}
      location={location}
     
      {...rest}
    />
  );
};

OttawaContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  authScopes: null,
};

OttawaContainerComponent.propTypes = {
  currentPage: string,
  currentSearchParams: object,
  authScopes: array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};




// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const OttawaContainer = compose(
  withRouter,
  connect(
    
   
  )
)(OttawaContainerComponent);

export default OttawaContainer;
