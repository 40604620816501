import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

const ShareChallengesComponent = ({ post }) => {
  const shareUrl = `https://learnactive.ca/challenges/${post.slug}`;
  const title = post.title.rendered;

  return (
    <div>
      <FacebookShareButton url={shareUrl} quote={title} className="share-button">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl} title={title} className="share-button">
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <EmailShareButton
        url={shareUrl}
        subject={title}
        body={`You should check out "${title}" on`}
        className="share-button"
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
};

export default ShareChallengesComponent;
