/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge, OwnListingLink } from '..';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const learnActiveBlogLink = (
    <NamedLink name="BlogPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.learnActiveBlogLink" />
    </NamedLink>
  );
  const challengeOfTheMonthLink = (
    <NamedLink name="BlogChallengesPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.challengeOfTheMonthLink" />
    </NamedLink>
  );
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const learnActiveBlog = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.learnActiveBlog" values={{ learnActiveBlogLink }} />
      </span>
    );
    const challengeOfTheMonth = (
      <span className={css.authenticationLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.challengeOfTheMonth"
          values={{ challengeOfTheMonthLink }}
        />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            {/* <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            /> */}
            <FormattedMessage
              id="TopbarMobileMenu.blog"
              values={{ lineBreak: <br />, learnActiveBlog }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <a className={css.createNewListingLink} id="toggleLosi">
            <span className={css.createListing} id="toggleLosi">
              Try Coach Losi
            </span>
          </a>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <NamedLink className={css.navigationLink} name="BlogPage">
          <FormattedMessage id="ProfilePage.myRedeemOptions" />
        </NamedLink>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <OwnListingLink
          listing={currentUserListing}
          listingFetched={currentUserListingFetched}
          className={css.navigationLink}
        />
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyLearnRewardsStatusPage'))}
          name="MyLearnRewardsStatusPage"
        >
          <FormattedMessage id="ProfilePage.myLearnRewardsStatus" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('HowLearnRewardsWorksPage'))}
          name="HowLearnRewardsWorksPage"
        >
          <FormattedMessage id="ProfilePage.howLearnRewardsWorks" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyRedeemOptionsPage'))}
          name="MyRedeemOptionsPage"
        >
          <FormattedMessage id="ProfilePage.myRedeemOptions" />
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>

        <a className={css.createListingLink} id="toggleLosi">
          <span className={css.createListing} id="toggleLosi">
            Try Coach Losi
          </span>
        </a>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
