import React, { Component } from 'react';
import axios from 'axios';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  Page,
} from '..';
import { StaticPage, TopbarContainer } from '../../containers';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './PostChallenges.module.css';
import ShareChallengesComponent from './ShareChallenges';
import { TailSpin } from 'react-loader-spinner';

class PostViewChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {
        title: {
          rendered: 'Challenges',
        },
      },
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    // function getCurrentDay() {
    //   const currentDate = new Date();
    //   const day = String(currentDate.getDate()).padStart(2, '0');
    //   const month = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    //   const year = currentDate.getFullYear();

    //   return day;
    // }
    // function getCurrentMonth() {
    //   const currentDate = new Date();
    //   const day = String(currentDate.getDate()).padStart(2, '0');
    //   const month = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    //   const year = currentDate.getFullYear();

    //   return month;
    // }
    const slug = this.props.params.slug;
    axios
      .get(`https://blog-api.learnactive.ca/wp-json/wp/v2/posts?slug=${slug}`)
      .then(post => {
        // axios
        //   .get(`/wp-json/wp/v2/posts?slug=${slug}`)
        //   .then(post => { // For dev environments
        let date = new Date(post.data[0].date);
        post.data[0].date = date.toLocaleDateString('en-US', {
          month: 'long',
        });

        if (post.data[0].content.rendered) {
          let parser = new DOMParser();
          let contentHTML = parser.parseFromString(post.data[0].content.rendered, 'text/html');
          let imgElements = contentHTML.getElementsByTagName('img');

          if (imgElements.length > 0) {
            post.data[0].imageURL = imgElements[0].src; // We just grab the first one
          }
        }

        if (post.data[0].featured_media) {
          // const image = axios.get(`/wp-json/wp/v2/media/${post.featured_media}`); // For dev environments
          const image = axios.get(
            `https://blog-api.learnactive.ca/wp-json/wp/v2/media/${post.featured_media}`
          );
          post.data[0].imageURL = image.data.source_url;
        }

        this.setState({
          post: post.data[0],
        });
      })
      .then(() => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '');

        console.log(day);
        const currentDay = `Day ${day}:`;
        // const currentDay = `Day 22:`;
        const currentDayAlt = `Day ${day}`;

        const strongElements = document.querySelectorAll(
          'article > div > blockquote > details > summary > strong'
        );
        const liElements = document.querySelectorAll('article li strong');
        const applyStyles1 = element => {
          if (element.innerText === currentDay || element.innerText === currentDayAlt) {
            const detailsElement = element.parentNode.parentNode;
            detailsElement.style.background = '#f3fcff';
            detailsElement.style.borderRadius = '16px';
            detailsElement.style.padding = '6px 24px 6px 24px';
            detailsElement.style.marginLeft = '-24px';
            detailsElement.style.border = '1px solid rgb(21 33 146 / 10%)'; // Add this line
          }
        };

        const applyStyles2 = element => {
          if (element.innerText === currentDay || element.innerText === currentDayAlt) {
            const detailsElement = element.parentNode;
            detailsElement.style.background = '#f3fcff';
            detailsElement.style.borderRadius = '16px';
            detailsElement.style.padding = '6px 24px 6px 24px';
            detailsElement.style.marginLeft = '-24px';
            detailsElement.style.border = '1px solid rgb(21 33 146 / 10%)'; // Add this line
          }
        };

        strongElements.forEach(applyStyles1);
        liElements.forEach(applyStyles2);
      })
      .catch(() => {});
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    console.log(this.state.post);
    let build;
    if (this.state.post.content) {
      build = (
        <div className={css.blogPage}>
          <h6 className={css.postDate}>
            <strong>{this.state.post.date}</strong>
          </h6>
          <h1 className={css.pageTitle}>{this.state.post.title.rendered}</h1>
          <article className={css.contentWrapper}>
            <div
              className={css.contentMain}
              dangerouslySetInnerHTML={this.createMarkup(this.state.post.content?.rendered)}
            />
          </article>
          <hr />
          <div className={css.postDateContainer}>
            <p className={css.postDatePreText}>
              Share {this.state.post.date}'s challenge with people you care about
            </p>
          </div>
          <ShareChallengesComponent post={this.state.post} />
        </div>
      );
    } else {
      build = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <TailSpin radius={10} color="#152192" />
        </div>
      );
    }
    const removePTags = input => {
      return input?.replace(/<\/?p[^>]*>/g, '');
    };
    let description = removePTags(this.state?.post.excerpt?.rendered);

    return (
      <StaticPage
        title={this.state?.post?.title?.rendered + ' | LearnActive'}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          description: description,
          name: 'Challenges page',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>{build}</LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

export default PostViewChallenges;
