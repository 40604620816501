import React, { useEffect, useState } from 'react';
import api from '../../strapi';
import { node } from 'prop-types';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  Page,
  PostView,
} from '../../components';
import { StaticPage, TopbarContainer } from '..';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './BlogViewPage.module.css';
// import { PrismicProvider } from '@prismicio/react';
// import { client } from '../../prismic';

const BlogViewPageComponent = props => {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await api.get('http://localhost:1337/api/blogs', {
  //       headers: {
  //         Authorization: `Bearer 3257401939c057e4177cd5a1c95fee30dc159035a3b19a4c3476741c512b4a130f7d3559cbdd4f4fe638e626cf5ff8041d62e9df131cfbd8cfce2bdb7eb3b30087f87e46c099004a7ce4844abbc3a617bb162fa66857136603640772235df6a532c675fdf2962518da2c286071081924a8267ea27db4e0b2aa7b63a0f7e63746`,
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     console.log(result);
  //     setData(result.data);
  //   };

  //   fetchData();
  // }, []);
  // console.log(data);
  // const { children, ...pageProps } = props;
  // return (
  //   <>
  //     <div>
  //       {data.map(item => (
  //         <div key={item.id}>{item.name}</div> // Assumes the restaurant has a 'name' field
  //       ))}
  //     </div>
  //   </>
  // );
  // return <Page {...pageProps}>{children}</Page>;
  return <PostView />;
  // <StaticPage
  //   title="Blog | LearnACtive"
  //   schema={{
  //     '@context': 'http://schema.org',
  //     '@type': 'BlogPage',
  //     description: 'About LearnActive',
  //     name: 'About page',
  //   }}
  // >
  //   <LayoutSingleColumn>
  //     <LayoutWrapperTopbar>
  //       <TopbarContainer />
  //     </LayoutWrapperTopbar>

  //     <LayoutWrapperMain className={css.staticPageWrapper}>
  //       <PostView />
  //     </LayoutWrapperMain>

  //     <LayoutWrapperFooter>
  //       <Footer />
  //     </LayoutWrapperFooter>
  //   </LayoutSingleColumn>
  // </StaticPage>
};

BlogViewPageComponent.defaultProps = {
  children: null,
};

BlogViewPageComponent.propTypes = {
  children: node,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const BlogViewPage = connect(mapStateToProps)(BlogViewPageComponent);

export default BlogViewPage;
