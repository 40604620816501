import RollerSkates from './SportImages/Background.jpg';
import Wakesurf from './SportImages/Background-1.jpg';
import Kitesurf from './SportImages/Background-2.jpg';
import BMX from './SportImages/Background-3.jpg';
import Golf from './SportImages/Background-4.jpg';
import Longboard from './SportImages/Background-5.jpg';
import Scooter from './SportImages/Background-6.jpg';
import Snocross from './SportImages/Background-7.jpg';
import  Tennis from './SportImages/Background-9.jpg';
import  Swimming from './SportImages/Background-8.jpg';
import Windsurf from './SportImages/Background-10.jpg';
import Wakeboard from './SportImages/Background-11.jpg';
import RockClimbing from './SportImages/Background-12.jpg';
import AlpineSkiing from './SportImages/Background-13.jpg';
import Climbing from './SportImages/Background-14.jpg';
import IceSkating from './SportImages/Background-15.jpg';
import MTB from './SportImages/Background-16.jpg';
import Skateboard from './SportImages/Background-17.jpg';
import Snowboarding from './SportImages/Background-18.jpg';  
import  WaterSkiing from './SportImages/Background-19.jpg';
import  Yoga from './SportImages/people-practicing-yoga-siloette.jpg';
import  Cycling from './SportImages/Background-21.jpg';



const Sports = [
  {
    id: "4",
    title: "Yoga",
    image: Yoga,
    category: "earth",
  },
  {
    id: "21",
    title: "Cycling",
    image: Cycling,
    category: "earth",
  },
  {
    id: "6",
    title: "Alpine Skiing",
    image: AlpineSkiing,
    category: "snow",
  },
  {
    id: "2",
    title: "Wakeboard",
    image: Wakeboard,
    category: "water",
  },
  {
    id: "1",
      title: "Swimming",
      image: Swimming,
      category: "water",
    },
    {
      id: "3",
      title: "Water Skiing",
      image: WaterSkiing,
      category: "water",
    },
  {
    id: "5",
    title: "Tennis",
    image: Tennis,
    category: "earth",
  },
  {
    id: "7",
    title: "Longboard",
    image: Longboard,
    category: "earth",
  },
  {
    id: "8",
    title: "Skateboard",
    image: Skateboard,
    category: "earth",
  },
  {
    id: "9",
    title: "Roller Skates",
    image: RollerSkates,
    category: "earth",
  },
  {
    id: "10",
    title: "Golf",
    image: Golf,
    category: "earth",
  },
  {
    id: "11",
    title: "Climbing",
    image: Climbing,
    category: "earth",
  },
  {
    id: "12",
    title: "BMX",
    image: BMX,
    category: "earth",
  },
  {
    id: "13",
    title: "MTB",
    image: MTB,
    category: "earth",
  },
  {
    id: "14",
    title: "Scooter",
    image: Scooter,
  },
  {
    id: "16",
    title: "Kitesurf",
    image: Kitesurf,
    category: "air",
  },
  {
    id: "17",
    title: "Windsurf",
    image: Windsurf,
    category: "air",
  },
  {
    id: "18",
    title: "Snowboarding",
    image: Snowboarding,
    category: "snow",
  },
  {
    id: "19",
    title: "Snocross",
    image: Snocross,
    category: "snow",
  },
  {
    id: "20",
    title: "Ice Skating",
    image: IceSkating,
    category: "snow",
  },
  ];

  export default Sports;