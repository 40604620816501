import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';
import { Button } from '..';
import { IconSpinner, IconApple } from '..';
import css from './SectionHeroCoachLosi.module.css';

const SectionHeroCoachLosi = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHeroCoachLosi.title1" />{' '}
          <FormattedMessage id="SectionHeroCoachLosi.title2" />
          <br></br>
          <FormattedMessage id="SectionHeroCoachLosi.title3" />
          <FormattedMessage id="SectionHeroCoachLosi.title4" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHeroCoachLosi.subTitle" />
        </h2>
        <div className={css.buttonContainer}>
          <a
            className={css.heroButton}
            target="_blank"
            href={'https://apps.apple.com/ca/app/coach-losi-ai-fitness-guide/id6474274390'}
          >
            <FormattedMessage id="SectionHeroCoachLosi.iosButton" />
          </a>
          {/* <NamedLink
            name="SearchPage"
            to={{
              search: 'address=Canada&bounds=83.1472069%2C-52.5210105%2C41.6400784%2C-141.010465',
            }}
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink> */}
          <a
            className={css.heroButtonBlue}
            target="_blank"
            href={'https://play.google.com/store/apps/details?id=com.learnactive.coachlosi'}
          >
            {/* <IconApple rootClassName={css.customIcon} /> */}
            <FormattedMessage id="SectionHeroCoachLosi.androidButton" />
          </a>
          {/* <NamedLink name="" className={css.heroButtonBlue}>
            <FormattedMessage id="SectionHeroCoachLosi.androidButton" />
          </NamedLink> */}
        </div>
      </div>
    </div>
  );
};

SectionHeroCoachLosi.defaultProps = { rootClassName: null, className: null };

SectionHeroCoachLosi.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHeroCoachLosi;
