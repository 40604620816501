import React, { Component, useState, useEffect, useRef } from 'react';
import PropTypes, { array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import * as Scroll from 'react-scroll';
import { Events, scroller, scrollSpy } from 'react-scroll';
import AnimatedShowMore from '@moondef/react-animated-show-more';

import { NamedLink, FilterPlain, FieldTextInput } from '..';

import css from './SectionSports.module.css';

import Sports from '../Sports';
import FilterButton from '../FilterButton/FilterButton';

let arrayForHoldingSports = [];
// const   = 19;

//filter Buttons
const Buttons = ({ filterItem, setItem, menuItems }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        {menuItems.map((Val, id) => {
          return (
            <button
              className="btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
              onClick={() => filterItem(Val)}
              key={id}
            >
              {Val}
            </button>
          );
        })}
        <button
          className="btn-dark text-white p-1 px-3 mx-5 fw-bold btn"
          onClick={() => setItem(Sports)}
        >
          All
        </button>
      </div>
    </>
  );
};

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const sportLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;

  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage id="SectionSports.listingsInSport" values={{ sport: nameText }} />
      </div>
    </NamedLink>
  );
};

const Card = ({ item }) => {
  // destructuring props

  return (
    <>
      {item.map(Val => {
        const searchQuery = Val.title;
        return (
          <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
            <div className="col-md-4 col-sm-6 card my-3 py-3 border-0">
              <div className={css.imageWrapper} key={Val.id}>
                <div className={css.aspectWrapper}>
                  <LazyImage src={Val.image} alt={Val.title} className={css.locationImage} />
                </div>
              </div>
              <div className={css.linkText}>
                <FormattedMessage
                  id="SectionSports.listingsInSport"
                  // values={Val.title}
                  values={{ sport: Val.title }}
                />
              </div>
            </div>
          </NamedLink>
        );
      })}
    </>
  );
};

const SectionSports = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  // filter
  const id = 'FilterPlainExample';
  const field = <FieldTextInput type="text" id={`${id}.input1`} name="input1" label="Input:" />;

  const [item, setItem] = useState(Sports);
  const menuItems = [...new Set(Sports.map(Val => Val.category))];

  // sports per page handler
  var number = 4;
  var prev = number;
  const [sportsToShow, setSportsToShow] = useState([]);
  const [showMore, setShowMore] = useState(number * 2);
  const [category, setCategory] = useState('all');
  const inAllView = !(showMore === arrayForHoldingSports.length + 5);

  const ref = useRef();
  // Show more

  const loopWithSlice = (start, end) => {
    const slicedSports = Sports.slice(start, end);
    arrayForHoldingSports = [...arrayForHoldingSports, ...slicedSports];
    setSportsToShow(arrayForHoldingSports);
    // setShowMore(showMore +  );
  };

  useEffect(() => {
    loopWithSlice(0, 4); // display 4 items on initial page load
  }, [category]);

  useEffect(() => {
    ref.current = showMore;
    prev = ref.current - 4; //assign the value of ref to the argument
  }, [showMore]); //this code will change when the value of prev changes

  const showMoreFunction = e => {
    loopWithSlice(prev, showMore);

    setShowMore(prevValue => prevValue + 4);
  };

  // filter
  const filterItem = curcat => {
    const newItem = Sports.filter(newVal => {
      return newVal.category === curcat;
    });
    arrayForHoldingSports.splice(0, arrayForHoldingSports.length, ...newItem);
    // setSportsToShow(newItem);

    loopWithSlice(sportsToShow, sportsToShow);
    setCategory(curcat);

    // setShowMore(4)
    //   setShowMore(newItem +  );}
  };

  const handleShowMoreSports = () => {
    loopWithSlice(showMore, showMore);
    setShowMore(showMore);
  };

  //scroller

  const scrollToSection = () => {
    scroller.scrollTo(css.title, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className={classes}>
      <div id="title" className={css.title}>
        <FormattedMessage id="SectionSports.title" />
      </div>
      <div className={css.sports}>
        <FilterButton filterItem={filterItem} setItem={setItem} menuItems={menuItems} />

        <Card item={sportsToShow} />
      </div>
      <div className={css.button}>
        {inAllView === true && category === 'all' ? (
          <button className={css.showMore} onClick={showMoreFunction}>
            Show more{' '}
          </button>
        ) : null}
        {/* <button  className={css.showMore} onClick={showMoreFunction}>Show more </button> */}
      </div>
    </div>
  );
};

SectionSports.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSports.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSports;

{
  /* {sportLink(
  'Roller Skate',
  nyImage,
  'https://cdn.pixabay.com/photo/2020/02/16/20/29/nyc-4854718_960_720.jpg'
)}
{sportLink(
  'WakeBoard',
  laImage,
  'https://cdn.pixabay.com/photo/2017/04/05/01/16/tropical-2203737_960_720.jpg'
)}
{sportLink(
  'Wakesurf',
  sfImage,
  '?address=San%20Francisco%2C%20California%2C%20USA&bounds=37.8324430069081%2C-122.354995082683%2C37.6044780500533%2C-122.517910874663'
)}

{sportLink(
  'Rock Climbing',
  nyImage,
  '?address=New%20York%20City%2C%20New%20York%2C%20USA&bounds=40.917576401307%2C-73.7008392055224%2C40.477399%2C-74.2590879797556'
)}
{sportLink(
  'Kitesurf',
  laImage,
  '?address=Los%20Angeles%2C%20California%2C%20USA&bounds=34.161440999758%2C-118.121305008073%2C33.9018913203336%2C-118.521456965901'
)}
{sportLink(
  'Alpine Skiing',
  sfImage,
  '?address=San%20Francisco%2C%20California%2C%20USA&bounds=37.8324430069081%2C-122.354995082683%2C37.6044780500533%2C-122.517910874663'
)} */
}
