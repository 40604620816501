import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';
import ClassLinkMaybe from './ClassLinkMaybe';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    hasClassLink,
    link,
    isCustomer,
  } = props;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      {isCustomer ? (
        <>
          <h2 className={css.detailCardTitle}>{listingTitle}</h2>

          {/* 
           //TODO: CAN BE TURNED BACK ON IF THE PRICE HAS BEEN IMPROVED TO BE DYNAMIC (GROUP AND PRIVATE)
          <p className={css.detailCardSubtitle}>{subTitle}</p> */}
        </>
      ) : null}

      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
      <ClassLinkMaybe link={link} hasClassLink={hasClassLink} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
