import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';
import { EditListingLocationForm, EditListingLocationOnlineForm } from '../../forms';
import config from '../../config';

import { ButtonTabNavHorizontal } from '..';

const selfLinkProps = {
  name: 'StyleguideComponent',
  params: { component: 'TabNavHorizontal' },
};

import css from './EditListingLocationOnlinePanel.module.css';

class EditListingLocationOnlinePanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      name: 'ZoomConnect',
      showHideIn_person: true,
      showHideOnline: false,
      initialValues: this.getInitialValues(),
      platformConnected: false,
    };

    this.hideComponent = this.hideComponent.bind(this);
  }
  hideComponent(name) {
    switch (name) {
      case 'showHideIn_person':
        this.setState({ showHideIn_person: !this.state.showHideIn_person });
        break;
      case 'showHideOnline':
        this.setState({ showHideOnline: !this.state.showHideOnline });
        break;
      default:
        null;
    }
  }

  getInitialValues() {
    const { listing } = this.props;

    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const onlineFieldsPresent = publicData && publicData.connected && publicData.platform;
    const isConnected = publicData && publicData.connected ? publicData.connected : false;
    const platform = publicData && publicData.platform ? publicData.platform : null;

    return {
      platform,
      connected: onlineFieldsPresent
        ? {
            connected: isConnected,
          }
        : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      disabledOnline,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;
    const { showHideIn_person, showHideOnline, initialValues, platformConnected } = this.state;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { description, title, publicData } = currentListing.attributes;
    var tab1selected = true;
    var tab2selected = false;
    const noop1 = () =>
      this.hideComponent('showHideIn_person') &
      this.hideComponent('showHideOnline') &
      (tab1selected = true) &
      (tab2selected = false) &
      this.forceUpdate();
    const noop2 = () =>
      this.hideComponent('showHideOnline') &
      this.hideComponent('showHideIn_person') &
      (tab2selected = true) &
      (tab1selected = false) &
      this.forceUpdate();

    var linkTabs = [
      { text: 'In-person', selected: false, onClick: noop1 },
      { text: 'Online', selected: true, onClick: noop2 },
      // { text: 'Hybrid', onClick: noop },
    ];
    console.log(updateInProgress);
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
   
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingLocationPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );
    const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
    console.log(platformConnected);
    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <ButtonTabNavHorizontal className={css.ButtonTabNavHorizontal} tabs={linkTabs} />
        <br />

        {showHideOnline && (
          <EditListingLocationOnlineForm
            className={css.form}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const { platform, connected } = values;
              const updateValues = {
                publicData: { platform: 'zoom', connected: true },
              };

              onSubmit(updateValues);
            }}
            onChange={onChange}
            disabled={!platformConnected}
            ready={ready}
            updated={platformConnected}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
          />
        )}
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationOnlinePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationOnlinePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationOnlinePanel;
