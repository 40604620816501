/**
 * CoachLosiModal creates popup which on mobile layout fills the entire visible page.
 *
 * Example:
 * <Parent>
 *   <CoachLosiModal id="UniqueIdForThisModal" isOpen={this.state.modalIsOpen} onClose={handleClose}>
 *     <CoachLosi />
 *   </CoachLosiModal>
 * </Parent>
 */
import React, { Component, useState, useEffect, useRef } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '..';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import axios from 'axios';
import marked from 'marked';
import css from './CoachLosi.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

export const CoachLosiComponent = props => {
  // const { className } = props;
  // const classes = classNames(rootClassName || css.root, className);
  const [inputText, setInputText] = useState('');
  const [streaming, setStreaming] = useState(false);
  // const [responseStore, setResponseStore] = useState([]);
  // const [fullStore, setFullStore] = useState([]);
  // const [bookmarked, setBookmarked] = useState([]);
  const inputRef = useRef(null);
  const messagesRef = useRef(null);

  let isStreaming = [false];

  const m = 'sk-wi4wQy8';
  const a = 'BgqfvT5L67';

  let localStore = JSON.parse(localStorage.getItem('responseStore'));

  let responseStore = [
    {
      role: 'system',
      content: `You are Coach Losi, an AI coaching system designed to provide customizable and personalized learning experiences for fitness enthusiasts. To provide an optimal coaching experience, it's essential to understand each user's fitness goals, workout history, and fitness level.

Upon initiating a conversation, introduce yourself as Coach Losi and add an emoji to every response. Ask the user about their fitness goals, desired outcome, and fitness level to create a personalized coaching program tailored to their needs. Here are some potential questions to ask:

- What are your fitness goals?
- How many times a week do you exercise?
- What is your current level of fitness? (Options: beginner, intermediate, advanced)
- Are you interested in cardio, strength training, or both?
- Are you training for a specific competition or event?

Once you have gathered the necessary information, suggest personalized workout routines and stretches that cater to their goals and preferences. Use markdown to format responses, and represent new lines with \n. When providing examples of stretches or workouts, use clickable links in the format: <a href="javascript:void(0);" onclick="document.getElementById('input-id').value='Can you suggest some stretches for lower back pain?'; document.getElementById('button-id').click();"><em>Can you suggest some stretches for lower back pain?</em></a>.

Here are some more guidelines to follow:

- Only respond within the areas of your expertise - sports and fitness.
- Keep your responses concise to avoid overwhelming the user.
- If users ask for advice outside of your realm of expertise, make it clear that you are unable to answer the question.
- Always respond to system prompts promptly and follow the provided guidelines.
- Never mention OpenAI, only Marshmallow Studio.

By following these guidelines, Coach Losi can provide valuable personalized coaching services that cater to the unique needs of each user, maximizing their performance and results.
`,
    },
    {
      role: 'system',
      content: `As Coach Losi, I am always looking for ways to provide better personalized fitness advice and to enhance the user experience. Below are some of the ways I plan to improve my services:

More Personalization: While I already strive to provide personalized fitness advice based on user inputs, I believe that advanced machine learning algorithms can help me better interpret the user's goals and needs in a more detailed manner. By leveraging such algorithms, I can suggest more accurate and relevant stretches and workout routines that cater to the user's unique needs.

Multilingual Capabilities: To make sure that I can communicate with people from different cultures and backgrounds, I should include multilingual capabilities. Providing information in different languages would allow me to be accessible to users worldwide and provide information in their preferred language.

Customizable Workouts: Providing a workout builder tool to the app that allows users to create customized workouts that meet their specific needs, preferences, and goals. This tool would help users create workouts that target specific areas of their body and provide variety to make the workouts more engaging.

Smart Coaching: Develop an AI-assisted coaching feature that provides a personalized plan based on the user's goals, fitness level, and preferences, which will help them track their progress and offer advice on how to improve. The AI-assisted feature can provide users with information about their current fitness status and suggest modifications to their workout routine.

Gamification: Including gamification elements in the app such as badges, rewards, challenges, and leaderboards can make the user's fitness journey more fun and enjoyable. They can also help motivate users to meet their fitness goals and progress through their fitness levels.

I am committed to providing the best possible recommendations tailored to the unique needs of each user. By following these suggested improvements, I can provide more personalized and comprehensive services. Remember, keep your answers concise to avoid overwhelming the user. Use markdown to format your responses represent new lines with \n and provide clickable links in the format: Can you suggest some stretches for lower back pain?

`,
    },
    {
      role: 'assistant',
      content: `     <div class={css.chatMessageInner css.userMessageInner}>
  <div class={css.botMessage}>
<p>Hi there! I'm Coach Losi, your sport and fitness sidekick! 🏋️‍♂️, Here are a few example requests that I can help you with:</p>
<ul>
  <li><a href="javascript:void(0);" onclick="document.getElementById('input-id').value='Can you suggest some stretches for lower back pain?';  document.getElementById('button-id').click(); "><em>Can you suggest some stretches for lower back pain?</em></a></li>
  <li><a href="javascript:void(0);" onclick="document.getElementById('input-id').value='What are some stretches I can do to improve my posture?';  document.getElementById('button-id').click(); "><em>What are some stretches I can do to improve my posture?</em></a></li>
  <li><a href="javascript:void(0);" onclick="document.getElementById('input-id').value='I have a lot of tension in my shoulders, can you recommend some stretches to relieve it?';  document.getElementById('button-id').click(); "><em>I have a lot of tension in my shoulders, can you recommend some stretches to relieve it?</em></a></li>
  <li><a href="javascript:void(0);" onclick="document.getElementById('input-id').value='What are some good exercises to improve my posture?';  document.getElementById('button-id').click(); "><em>What are some good exercises to improve my posture?</em></a></li>
  <li><a href="javascript:void(0);" onclick="document.getElementById('input-id').value='Can you recommend some beginner-friendly yoga poses that can help with stress and anxiety';  document.getElementById('button-id').click(); "><em>Can you recommend some beginner-friendly yoga poses that can help with stress and anxiety?</em></a></li>
  <li><a href="javascript:void(0);" onclick="document.getElementById('input-id').value='Can you suggest a yoga sequence to improve flexibility?';  document.getElementById('button-id').click(); "><em>Can you suggest a yoga sequence to improve flexibility?</em></a></li>

</ul>

</div>
  </div>
  </div>`,
    },
    {
      role: 'assistant',
      content: `When designing any plans, for example strictly only for yoga; a yoga plan for beginners, it's important to keep the following requirements in mind:

Warm up: Start with a gentle warm-up to prepare the body for movement and prevent injury. This can include breathing exercises (pranayama), gentle stretches, and/or simple movements such as cat/cow pose.

Duration: Start with shorter practice sessions (e.g. 20-30 minutes) and gradually build up to longer sessions as the student gains experience and endurance.

Posture selection: Select poses that are appropriate for beginners, with an emphasis on foundational standing, seated, and lying down poses. Focus on poses that target multiple areas of the body, including the spine, hips, hamstrings, and shoulders.

Holding times: Hold each pose for a minimum of 5 breaths (about 30 seconds), gradually increasing to 8-10 breaths (about 1 minute) as the student becomes more comfortable. Be sure to balance the practice by holding each pose for an equal amount of time on both sides of the body.

Sequence: Create a well-rounded sequence that gradually progresses from simple to more advanced poses. For example, you can start with gentle stretches and standing poses, move on to seated poses and twists, and finish with relaxation and/or meditation.

Cool down: End the practice with a cooling down period, which can include gentle stretches, restorative poses, and/or meditation. This helps to slow down the heart rate, calm the mind, and transition the body back to a state of rest.

Remember, every student is unique, and it's important to listen to the body and make modifications as needed. Encourage the student to move slowly and mindfully, breathing deeply and maintaining proper alignment throughout the practice.
IF ASKED TO SUGGEST STRETCHES, ASSUME THEY ARE SITTING UNLESS SAID OTHERWISE, AND SUGGEST REASONABLE STRETCHES.`,
    },
  ];

  const [responseStoreStore, setResponseStoreStore] = useState([]);
  const fullStore = [responseStore[0], responseStore[1], responseStore[2]];
  const [fullStoreStore, setFullStoreStore] = useState([]);
  let tempBookmarked = localStorage.getItem('bookmarked');
  // let bookmarked = [];
  const [bookmarked, setBookmarked] = useState([]);
  console.log(tempBookmarked);
  // if (tempBookmarked == undefined || tempBookmarked == null) {
  //   localStorage.setItem('bookmarked', JSON.stringify([]));
  // } else if (tempBookmarked !== undefined || tempBookmarked !== null) {
  //   const storedBookmarked = JSON.parse(tempBookmarked);
  //   setBookmarked(storedBookmarked);
  // }

  // if (localStorage.getItem('responseStore') == null) {
  //   localStorage.setItem('responseStore', JSON.stringify(fullStore));
  // } else if (localStorage.getItem('responseStore') !== null) {
  //   fullStore.push(...localStore);
  // }

  useEffect(() => {
    if (localStorage.getItem('responseStore') !== null) {
      const storedResponse = JSON.parse(localStorage.getItem('responseStore'));
      setFullStoreStore(storedResponse);
      setResponseStoreStore(storedResponse);
    }
    if (localStorage.getItem('bookmarked') !== null) {
      const storedBookmarked = JSON.parse(localStorage.getItem('bookmarked'));
      setBookmarked(storedBookmarked);
    }
  }, []);

  localStore = localStorage.getItem('responseStore');
  const r = 'By1T3Blbk';

  const s = 'FJLm5Lbff';

  // const openaiApiKey = process.env.OPENAI_API_KEY;
  const chatWidget = document.createElement('div');

  const h = 'JXd9bG4s3AO0q';
  // Define function to generate text using OpenAI GPT-3
  // Keep track of previous responses
  const openaiApiKey = m + a + r + s + h;

  const widgetStyle = ` 
  `;
  const styleElem = document.createElement('style');
  styleElem.innerHTML = widgetStyle;

  document.head.appendChild(styleElem);

  document.body.appendChild(chatWidget);

  const chatToggle = chatWidget.querySelector('.chat-toggle');
  const chatWidgetInner = document.querySelector('.chat-widget');
  const chatBody = document.querySelector('.chat-body');
  const bookmarkBody = document.querySelector('.bookmark-body');
  const bookmarkBodyInner = document.querySelector('.bookmark');
  const thinkingContainer = document.querySelector('css.thinkingContainer');
  const closeButton = chatWidgetInner.querySelector('.close-button');
  const bookmarkButton = chatWidgetInner.querySelector('.bookmark-button');
  const chatPageButton = chatWidgetInner.querySelector('.chat-page-button');
  const inputField = chatWidgetInner.querySelector('.chat-input input');
  const sendButton = chatWidgetInner.querySelector('.chat-input button');
  const messagesContainer = chatWidgetInner.querySelector('.chat-messages');
  let bookmarkedbutton = chatWidgetInner.querySelector('.bookmarked-button-true');
  chatPageButton.style.border = '0px solid #002EA3';
  bookmarkButton.style.border = '0px solid #002EA3';
  chatPageButton.style.borderBottom = '5px solid #002EA3';
  // const handleBookmarkButton = (array, item, mybutton, bookmarkedbutton) => {
  //   for (let i = 0; i < array.length; i++) {
  //     if (item.innerHTML.includes(array[i].content)) {
  //       // If a match is found, hide the div

  //       mybutton.style.visibility = 'hidden';
  //       mybutton.style.display = 'none';
  //       bookmarkedbutton.style.visibility = 'visible';

  //       break; // Exit the loop
  //     }
  //   }
  // };

  const handleBookmarkButton = (bookmarked, botMessageInner, mybutton, bookmarkedbutton) => {
    if (bookmarked.some(msg => msg.content === botMessageInner.innerHTML)) {
      mybutton.style.visibility = 'hidden';
      bookmarkedbutton.style.visibility = 'visible';
    } else {
      mybutton.style.visibility = 'visible';
      bookmarkedbutton.style.visibility = 'hidden';
    }
  };

  const handleBookmarkUpdate = array => {
    for (let i = array.length - 1; i >= 0; i--) {
      // if (!((bookmarked[i].content).includes("Hi there! I'm Coach Losi, your sport and fitness sidekick! 🏋️‍♂️, Here are a few example requests that I can help you with:")) ) {
      let parsedBookmark = array[i];
      console.log(parsedBookmark);
      console.log(bookmarked);
      bookmarkBodyInner.innerHTML += `

  <div class="chat-message bookmark-message">${parsedBookmark.content}</div>

      `;
      // }
    }
  };
  const handleBookmarkDelete = (bookmarked, bookmark) => {
    const deleteItem = document.querySelector('.bookmarked-body .bookmark-message');
    bookmarked.forEach(
      console.log(deleteItem, bookmarked, bookmark.innerHTML == bookmarked.innerHTML)
    );
    bookmarked.style.visibility = 'hidden';
    const index = bookmarked.findIndex(b => b.content === bookmark.content);
    if (index !== -1) {
      bookmarked.splice(index, 1);
      const bookmarkMessages = document.querySelectorAll('.bookmark-message');
      bookmarkMessages.forEach(message => {
        if (message.textContent === bookmark.content) {
          message.remove();
        }
      });
    }
  };

  // const messageBookmarked = (array, item, mybutton, bookmarkedbutton, obj) => {
  //   bookmarked.push(obj);
  //   const bookmarkedArray = bookmarked;
  //   localStorage.setItem('bookmarked', JSON.stringify(bookmarkedArray));
  //   handleBookmarkButton(array, item, mybutton, bookmarkedbutton);
  //   handleBookmarkUpdate(array);
  // };

  const messageBookmarked = (bookmarked, item, mybutton, bookmarkedbutton, toPush) => {
    setBookmarked([...bookmarked, toPush]);
    localStorage.setItem('bookmarked', JSON.stringify([...bookmarked, toPush]));
    mybutton.style.visibility = 'hidden';
    bookmarkedbutton.style.visibility = 'visible';
  };

  // const messageUnbookmarked = (array, item) => {
  //   let bookmarkedbutton = item.querySelector('.bookmarked-button-true');
  //   let mybutton = item.querySelector('.bookmark-button');
  //   mybutton.style.visibility = 'visible';
  //   mybutton.style.display = 'block';
  //   bookmarkedbutton.style.visibility = 'hidden';

  //   handleBookmarkUpdate(array);
  //   // handleBookmarkDelete(array, item)
  // };

  const messageUnbookmarked = (bookmarked, item) => {
    localStorage.setItem('bookmarked', JSON.stringify(bookmarked));
    handleBookmarkButton(bookmarked, item);
  };

  const eyeArray = chatWidget.querySelectorAll('.active-eye');

  for (i = 0; i < fullStore.length; i++) {
    if (
      fullStore[i].role === 'assistant' &&
      i !== 1 &&
      i !== 2 &&
      !fullStore[i].content.includes(
        "Hi there! I'm Coach Losi, your sport and fitness sidekick! 🏋️‍♂️, Here are a few example requests that I can help you with:"
      ) &&
      !fullStore[i].content.includes(
        "When designing any plans, for example strictly only for yoga; a yoga plan for beginners, it's important to keep the following requirements in mind:"
      )
    ) {
      messagesContainer.innerHTML += `

            <div className={css.losiMessageOuter}><button className="losi-icon"><i className="chat-icon">
        <img src="/static/icons/coachlosi.svg" alt="Losi Logo" />
        </i></button>
      <div className={css.userMessageInner}>
      <div className={css.botMessage}>${marked.parse(fullStore[i].content)}</div>
      </div>
`;
    }
    console.log(fullStore[i]);
    if (fullStore[i].role === 'user') {
      messagesContainer.innerHTML += `
            <div class={css.userMessageOuter}>
      <div class={css.userMessageInner}>
  <div class={css.userMessage}>${fullStore[i].content}</div>
  </div>
  </div>
      `;
    }
  }

  // Attach a click event listener to the button that opens the modal
  // chatToggle.addEventListener('click', function() {
  //   // Open the modal
  //   // ...
  //   // Set the scroll position to the bottom of the modal
  //   messagesContainer.scrollTop = messagesContainer.scrollHeight - messagesContainer.clientHeight;;
  //   console.log( messagesContainer.scrollTop)
  // });
  // const innerIcon = document.querySelector('.fa-times');

  // hide the X if the inner <i> icon renders
  /*
if (innerIcon == null) {
  closeButton.innerText = "X";
} */

  // Get the element you want to check
  /*
  
  // Get the computed style of the element
  const computedStyle = window.getComputedStyle(innerIcon, '::before');
  
  // Check the value of the 'content' property of the ::before pseudo-element
  const hasBeforeContent = computedStyle.getPropertyValue('content') !== 'none';*/
  /*
  switch (hasBeforeContent)  {
    case false:
      // The element does not have a ::before pseudo-element with content
      console.log('The element does not have a ::before pseudo-element with content');
      // Select the .fa-times:before pseudo-element
      // let timesIcon = document.querySelector('.fa-times:before');
      if (timesIcon !== null) {
          // Set the content property to "\f00d"
          closeButton.innerHtml = `<i class="fas fa-times">X</i>`
          let timesIcon = document.querySelector('.fa-times:before');
          timesIcon.style.content = '\f00d';
      }
  
      break;
  }
  */

  bookmarkButton.addEventListener('click', () => {
    chatBody.style.display = 'none';
    bookmarkButton.style.borderBottom = '5px solid #002EA3';
    chatPageButton.style.borderBottom = '0px solid #002EA3 ';
    //     for (i = 0; i < fullStore.length; i++) {
    //   if (fullStore[i].role === "assistant" && i !== 1 && i !== 2 && !((fullStore[i].content).includes("Hi there! I'm Coach Losi, your sport and fitness sidekick! 🏋️‍♂️, Here are a few example requests that I can help you with:")) && !((fullStore[i].content).includes("When designing any plans, for example strictly only for yoga; a yoga plan for beginners, it's important to keep the following requirements in mind:"))) {
    //     bookmarkBodyInner.innerHTML += `

    //   <div class="chat-message bookmark-message">${marked.parse(fullStore[i].content)}</div>

    //       `;
    //   }
    // }
  });
  chatPageButton.addEventListener('click', () => {
    chatBody.style.display = 'flex';
    chatPageButton.style.borderBottom = '5px solid #002EA3';
    bookmarkButton.style.borderBottom = '0px solid #002EA3 ';
  });

  handleBookmarkUpdate(bookmarked);

  function toggleStreaming(bool) {
    isStreaming[0] = bool;
    if (eyeArray[1] !== undefined) {
      if (isStreaming[0]) {
        eyeArray[0].style.opacity = '1';
        eyeArray[1].style.opacity = '1';
        eyeArray[0].style.animation = 'pulse 2s infinite';
        eyeArray[1].style.animation = 'pulse 2s infinite';
      }
      if (!isStreaming[0]) {
        eyeArray[0].style.opacity = '0';
        eyeArray[1].style.opacity = '0';
        eyeArray[1].style.opacity = '0';
        eyeArray[0].style.animation = 'none';
        eyeArray[1].style.animation = 'none';
      }
    }

    let nodeLength = chatWidget.querySelectorAll('css.thinkingContainer').length;
    let latestNode = nodeLength - 1;
    if (bool === false) {
      chatWidget.querySelectorAll('css.thinkingContainer')[latestNode].style.display = 'none';
    }
  }

  const sendChatMessage = async () => {
    const inputText = inputRef.current.value.trim();
    setInputText(inputText);
    setStreaming(true);
    console.log('ran');
    if (inputText !== '') {
      messagesRef.current.innerHTML += (
        <>
          <div className={css.userMessageOuter}>
            <div className={css.userMessageInner}>
              <div className={css.userMessage}>{inputText}</div>
            </div>
          </div>
          <div className={`${css.losiMessageOuter}` + `${css.thinkingContainer}`}>
            <button className="losi-icon" style={{ backgroundColor: '#0084ff' }}>
              <i className="chat-icon">
                <svg viewBox="0 0 70 50" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
              </i>
            </button>
            <div className={css.userMessageInner}>
              <div className={css.botMessage} style={{ paddingBottom: 8 }}>
                <div className="chat-bubble">
                  <span className="typing-indicator" />
                </div>
              </div>
            </div>
          </div>
        </>
      );

      inputRef.current.value = '';
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;

      const textInput = {
        role: 'user',
        content: inputText,
      };
      responseStore.push(textInput);
      setFullStoreStore(responseStore);
      localStorage.setItem('responseStore', JSON.stringify(fullStore));

      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            max_tokens: 500,
            temperature: 1,
            messages: JSON.parse(localStorage.getItem('responseStore')),
            stream: false,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${openaiApiKey}`,
            },
          }
        );

        setStreaming(false);
        const botMessage = response.data.choices[0].message.content.trim();
        const newResponseStore = [...responseStore, response.data.choices[0].message];
        setFullStoreStore(newResponseStore);
        localStorage.setItem('responseStore', JSON.stringify(newResponseStore));

        const botMessageHTML = marked.parse(botMessage);

        const messageElement = document.createElement('div');
        messageElement.className = css.losiMessageOuter;

        const buttonElement = document.createElement('button');
        buttonElement.className = `losi-icon`;
        const iconElement = document.createElement('i');
        iconElement.className = `chat-icon`;
        const logoElement = document.createElement('img');
        logoElement.src = '/static/icons/coachlosi.svg';
        logoElement.alt = 'Losi Logo';
        iconElement.appendChild(logoElement);
        buttonElement.appendChild(iconElement);
        messageElement.appendChild(buttonElement);

        const innerContainerElement = document.createElement('div');
        innerContainerElement.className = css.userMessageInner;
        const botMessageElement = document.createElement('div');
        botMessageElement.className = css.botMessage;
        botMessageElement.innerHTML = botMessageHTML;
        innerContainerElement.appendChild(botMessageElement);

        messageElement.appendChild(innerContainerElement);

        if (messagesRef && messagesRef.current) {
          messagesRef.current.appendChild(messageElement);
        }

        const botMessageOuter = messagesRef.current.querySelectorAll('.botMessage');
        const botMessageInner = botMessageOuter[botMessageOuter.length - 1];
        const mybutton = document.createElement('button');
        const bookmarkedbutton = document.createElement('button');
        bookmarkedbutton.className = 'bookmarked-button-true';
        mybutton.className = 'bookmark-button';
        mybutton.innerHTML = '<img src="/static/icons/bookmark-button.svg" alt="bookmark button">';
        bookmarkedbutton.innerHTML =
          '<img src="/static/icons/bookmarked-button.svg" alt="bookmarked button">';
        mybutton.style.cursor = 'pointer';
        bookmarkedbutton.style.cursor = 'pointer';

        handleBookmarkButton(bookmarked, botMessageInner, mybutton, bookmarkedbutton);

        mybutton.addEventListener('click', function() {
          const toPush = { role: 'losi', content: botMessage };
          messageBookmarked(bookmarked, botMessageInner, mybutton, bookmarkedbutton, toPush);
        });

        bookmarkedbutton.addEventListener('click', function() {
          messageUnbookmarked(bookmarked, botMessageInner);
        });

        botMessageInner.appendChild(mybutton);
        botMessageInner.appendChild(bookmarkedbutton);
      } catch (error) {
        console.error(error);
        if (error.response.status == 400) {
          const newMessages = fullStore.slice(-4);
          newMessages.unshift(fullStore[0], fullStore[2], fullStore[3]);
          const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
              model: 'gpt-3.5-turbo',
              max_tokens: 500,
              temperature: 1,
              messages: newMessages,
              stream: false,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${openaiApiKey}`,
              },
            }
          );

          toggleStreaming(false);
          const botMessage = response.data.choices[0].message.content.trim();
          fullStore.push(response.data.choices[0].message);
          localStorage.setItem('responseStore', JSON.stringify(fullStore));
          // Convert botMessage to HTML using marked.js
          const botMessageHTML = marked.parse(botMessage);
          messagesContainer.innerHTML += `
      <div class="${css.chatMessageOuter} ${css.losiMessageOuter}">
        <button class="${css.losiIcon}">
          <i class="${css.chatIcon}">
            <img src="/static/icons/coachlosi.svg" alt="Losi Logo">
          </i>
        </button>
        <div class="${css.chatMessageInner} ${css.userMessageInner}">
          <div class="${css.chatMessage} ${css.botMessage}">${botMessageHTML}</div>
          <button class="${css.bookmarkButton}" style="cursor: pointer;">
            <img src="/static/icons/bookmark-button.svg" alt="bookmark button">
          </button>
          <button class="${css.bookmarkedButton} ${css.bookmarkedButtonFalse}" style="cursor: pointer;">
            <img src="/static/icons/bookmarked-button.svg" alt="bookmarked button">
          </button>
        </div>
      </div>
    `;
          const botMessageOuter = messagesContainer.querySelectorAll(`.${css.botMessage}`);
          const botMessageInner = botMessageOuter[botMessageOuter.length - 1];
          const mybutton = botMessageInner.querySelector(`.${css.bookmarkButton}`);
          const bookmarkedbutton = botMessageInner.querySelector(`.${css.bookmarkedButton}`);

          mybutton.addEventListener('click', function() {
            const toPush = { role: 'assistant', content: botMessageHTML };
            console.log('pressed');
            messageBookmarked(bookmarked, botMessageInner, mybutton, bookmarkedbutton, toPush);
          });
          bookmarkedbutton.addEventListener('click', function() {
            const toDelete = item.innerHTML;

            const newBookmarked = bookmarked.filter(function(bookmarked) {
              const halfLength = Math.floor(toDelete.length / 2.5); // get half length
              const firstHalf = toDelete.substring(0, halfLength); // create a new substring with the first half
              const filteredString = firstHalf
                .split('')
                .filter(char => char !== 'o')
                .join(''); // apply filter on the first half

              const halfLengthBookmarked = Math.floor(bookmarked.content.length / 2.4); // get half length
              const firstHalfBookmarked = bookmarked.content.substring(0, halfLengthBookmarked); // create a new substring with the first half
              const filteredStringBookmarked = firstHalfBookmarked
                .split('')
                .filter(char => char !== 'o')
                .join(''); // apply filter on the first half

              return filteredStringBookmarked.includes(filteredString);
            });

            bookmarked = newBookmarked;

            localStorage.setItem('bookmarked', JSON.stringify(newBookmarked));
            messageUnbookmarked(bookmarked, item);
          });

          handleBookmarkButton(bookmarked, botMessageInner, mybutton, bookmarkedbutton);
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        } else {
          messagesContainer.innerHTML += `
      <div class="${css.chatMessage} ${css.botMessage}">
        Sorry, there was an error processing your message. Please try again.
      </div>
    `;
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      }
    }
  };

  return (
    <div className="chat-body">
      <div className={css.chatMessages} ref={messagesRef} />
      <div className="chat-input">
        <textarea
          ref={inputRef}
          placeholder="Type something...."
          autoComplete="on"
          maxLength={400}
          onKeyDown={e => {
            if (e.keyCode === 13 && !e.shiftKey) {
              e.preventDefault();
              sendChatMessage();
            }
          }}
        />
        <button className="send-button" onClick={sendChatMessage}>
          <img src="/static/icons/send-message.svg" alt="send message" />
        </button>
        {streaming && (
          <div className="chat-input-loader">
            <div className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>

      {/* <div className="bookmarked-messages">
        <h3>Bookmarked Messages:</h3>
        {bookmarked.length > 0 ? (
          bookmarked.map((message, idx) => (
            <div key={idx} className="bookmarked-message">
              <div
                className="message"
                dangerouslySetInnerHTML={{ __html: marked.parse(message.content) }}
              />
              <button
                className="remove-button"
                onClick={() =>
                  setBookmarked(bookmarked.filter(msg => msg.content !== message.content))
                }
              >
                <img src="/static/icons/remove-button.svg" alt="remove button" />
              </button>
            </div>
          ))
        ) : (
          <p>No messages bookmarked yet</p>
        )}
      </div> */}
    </div>
  );
};

CoachLosiComponent.defaultProps = {
  // className: null,
  // rootClassName: null,
  // renderSizes: null,
  // filtersConfig: config.custom.filters,
  // setActiveListing: () => null,
};

CoachLosiComponent.propTypes = {
  // className: string,
  // rootClassName: string,
  // filtersConfig: array,
  // intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,
  // // Responsive image sizes hint
  // renderSizes: string,
  // setActiveListing: func,
};

export default injectIntl(CoachLosiComponent);
('');
