import React, { useEffect, useState } from 'react';
import api from '../../strapi';
import { node } from 'prop-types';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  Page,
  PostList,
} from '../../components';
import { StaticPage, TopbarContainer } from '..';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './BlogPage.module.css';
// import { PrismicProvider } from '@prismicio/react';
// import { client } from '../../prismic';

const BlogPageComponent = props => {
  const [data, setData] = useState([]);

  return (
    <StaticPage
      title="Blog | LearnActive"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BlogPage',
        description:
          'Stay up to date with the latest trends, tips, and insights in fitness, sports, and wellness.',
        name: 'Blog page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <PostList />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

BlogPageComponent.defaultProps = {
  children: null,
};

BlogPageComponent.propTypes = {
  children: node,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const BlogPage = connect(mapStateToProps)(BlogPageComponent);

export default BlogPage;
