import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '..';
import { PostListChallenges } from '..';
import { Button } from '..';
import css from './SectionDownloadCoachLosi.module.css';
import { NamedLink } from '..';

const SectionDownloadCoachLosi = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        Ready To Transform Your Fitness Game?
        {/* <FormattedMessage id="SectionDownloadCoachLosi.titleLineOne" />
        <br />
        <FormattedMessage id="SectionDownloadCoachLosi.titleLineTwo" /> */}
      </div>

      <p className={css.subtitle}>
        Achieve your fitness dreams with the companion that knows you best. Download Now, Available
        on iOS and Google Play Store.
      </p>
      <div className={css.buttonContainer}>
        <a
          className={css.heroButton}
          target="_blank"
          href={'https://apps.apple.com/ca/app/coach-losi-ai-fitness-guide/id6474274390'}
        >
          <FormattedMessage id="SectionHeroCoachLosi.iosButton" />
        </a>
        {/* <NamedLink
            name="SearchPage"
            to={{
              search: 'address=Canada&bounds=83.1472069%2C-52.5210105%2C41.6400784%2C-141.010465',
            }}
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink> */}
        <a
          className={css.heroButtonBlue}
          target="_blank"
          href={'https://play.google.com/store/apps/details?id=com.learnactive.coachlosi'}
        >
          <FormattedMessage id="SectionHeroCoachLosi.androidButton" />
        </a>
        {/* <NamedLink name="" className={css.heroButtonBlue}>
            <FormattedMessage id="SectionHeroCoachLosi.androidButton" />
          </NamedLink> */}
      </div>
      <img
        id="CoachLosi"
        className={css.mockup}
        src="/static/app-mockup.svg"
        alt="Comprehensive Workouts"
      ></img>
      {/* <PostListChallenges return="1" /> */}
    </div>
  );
};

SectionDownloadCoachLosi.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionDownloadCoachLosi.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionDownloadCoachLosi;
