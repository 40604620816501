import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '..';

import css from './SectionWhyCoachLosi.module.css';

const SectionWhyCoachLosiLanding = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.titleOuterDiv}>
        <div className={css.titleDiv}>
          <div className={css.title}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.titleLineOne" />
            <br />
          </div>
          <p className={css.subTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.titleLineTwo" />
          </p>
        </div>
        <img
          id="CoachLosi"
          className={css.mockup}
          src="/static/app-mockup2.svg"
          alt="Comprehensive Workouts"
        ></img>
      </div>
      {/* <div className={css.steps}> */}
      <div className={css.steps}>
        <div className={css.blues}>
          <img
            id="toggleLosi"
            className={css.icon}
            src="/static/icons/custom-workout.svg"
            alt="Custom Workout Plans"
          ></img>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part1Text" />
          </p>
        </div>

        <div className={css.blues}>
          <img
            id="toggleLosi"
            className={css.icon}
            src="/static/icons/expert-guidance.svg"
            alt="Expert Guidance"
          ></img>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part2Text" />
          </p>
        </div>
        <div className={css.blues}>
          <img
            id="toggleLosi"
            className={css.icon}
            src="/static/icons/track-progress.svg"
            alt="Track Your Progress"
          ></img>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part3Text" />
          </p>
        </div>
      </div>
      <div className={css.steps}>
        <div className={css.blues}>
          <img
            id="toggleLosi"
            className={css.icon}
            src="/static/icons/comprehensive-workouts.svg"
            alt="Comprehensive Workouts"
          ></img>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part4Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part4Text" />
          </p>
        </div>

        <div className={css.blues}>
          <img
            id="toggleLosi"
            className={css.icon}
            src="/static/icons/accessible-anywhere.svg"
            alt="Accessible Anywhere"
          ></img>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part5Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part5Text" />
          </p>
        </div>
        <div className={css.blues}>
          <img
            id="toggleLosi"
            className={css.icon}
            src="/static/icons/stay-engaged.svg"
            alt="Stay Engaged"
          ></img>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part6Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part6Text" />
          </p>
        </div>
      </div>

      {/* 
        <div className={css.step}>
          {/* <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosiLanding.part3Text" />
          </p> *
          <button id="toggleLosi" className={css.toggleLosi}>
            <img
              id="toggleLosi"
              className={css.coachLosiRight}
              src="/static/icons/CoachLosiCircleAsset-cp.gif"
              alt="Coach Losi"
            ></img>
          </button>
          <button name="Losi" className={css.heroButton} id="toggleLosi">
            Ask Coach Losi
          </button>
        </div> 
        */}
      {/* </div> */}
      <div className={css.createListingLink}>
        <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink>
      </div>
    </div>
  );
};

SectionWhyCoachLosiLanding.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionWhyCoachLosiLanding.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionWhyCoachLosiLanding;
