import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title1" /> <FormattedMessage id="SectionHero.title2" />
          <br></br>
          <FormattedMessage id="SectionHero.title3" />
          <FormattedMessage id="SectionHero.title4" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div className={css.buttonContainer}>
          <button name="Losi" className={css.heroButton} id="toggleLosi">
            Ask Coach Losi
          </button>
          {/* <NamedLink
            name="SearchPage"
            to={{
              search: 'address=Canada&bounds=83.1472069%2C-52.5210105%2C41.6400784%2C-141.010465',
            }}
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink> */}
          <NamedLink name="NewListingPage" className={css.heroButtonBlue}>
            <FormattedMessage id="SectionHero.joinButton" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
