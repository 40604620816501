import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import css from './Post.module.css';

class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
    this.createMarkup = this.createMarkup.bind();
  }

  async componentDidMount() {
    // const posts = await axios.get('/wp-json/wp/v2/posts'); // For dev environments
    const posts = await axios.get('https://blog-api.learnactive.ca/wp-json/wp/v2/posts');
    const updatedPosts = await Promise.all(
      posts.data.map(async post => {
        if (post.content.rendered) {
          let parser = new DOMParser();
          let contentHTML = parser.parseFromString(post.content.rendered, 'text/html');
          let imgElements = contentHTML.getElementsByTagName('img');

          if (imgElements.length > 0) {
            post.imageURL = imgElements[0].src; // We just grab the first one
          }
        }

        let date = new Date(post.date);
        post.date = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        if (post.featured_media) {
          // const image = await axios.get(`/wp-json/wp/v2/media/${post.featured_media}`); // For dev environments
          const image = await axios.get(
            `https://blog-api.learnactive.ca/wp-json/wp/v2/media/${post.featured_media}`
          );
          post.imageURL = image.data.source_url;
        }
        // removing <p>, </p> tags from the excerpt
        let text = post.excerpt.rendered.replace(/<\/?p>/g, '');

        // Establish the limit for the excerpt length
        let maxLength = 160; // Adjust this value according to your needs

        if (text.length > maxLength) {
          // Find the last space (< maxLength)
          let trimmedText = text.substr(0, maxLength);
          trimmedText = trimmedText.substr(
            0,
            Math.min(trimmedText.length, trimmedText.lastIndexOf(' '))
          );

          // Reassign the truncated version to the excerpt
          post.excerpt.rendered = `<p>${trimmedText.trim()}…</p>`;
        }

        return post;
      })
    );

    this.setState({
      posts: updatedPosts,
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    // ? image as card background
    return (
      <>
        <h1 className={css.header}>
          📣 Welcome to the <span>LearnActive Blog!</span> 📚📝
        </h1>
        <p className={css.subtitle}>
          Stay up to date with the latest trends, tips, and insights in fitness, sports, and
          wellness. Our blog is a hub for all things active living, providing you with expert advice
          and inspiration to help you achieve your health and fitness goals. Whether you're a
          beginner or a seasoned athlete, we've got you covered!
        </p>
        <div className={css.postList}>
          {this.state.posts.map(post => (
            <Link to={`/blog/${post.slug}`} key={post.id} className={css.postLink}>
              <div className={css.cardWrapper}>
                <div
                  className={css.cardBackground}
                  style={{
                    backgroundImage: `url(${post.imageURL})`,
                    // boxShadow: `0 0 20px ${gradients[gradientIndex++ % gradients.length]}`,
                  }}
                />
                <div className={css.cardContent}>
                  <h3 className={css.postTitle}>{post.title.rendered}</h3>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(post.excerpt.rendered)}
                    className={css.postExcerpt}
                  />
                  {post.imageURL && (
                    <img src={post.imageURL} alt="Post" className={css.postImage} />
                  )}
                  <p className={css.postDate}>{post.date}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </>
    );
  }
}

export default PostList;
