import React, { useState, useEffect } from 'react';
import { bool, object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { TopbarSearchForm } from '../../forms';
import {
  Page,
  SectionHeroCoachLosi,
  SectionHowItWorks,
  SectionDownloadCoachLosi,
  SectionWhyCoachLosiLanding,
  SectionLocations,
  SectionSports,
  SectionLearnRewards,
  Topbar,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  PostListChallenges,
} from '../../components';
import { TopbarContainer, OttawaContainer } from '..';

import facebookImage from '../../assets/learnactiveFacebook-1200x630.jpeg';
import twitterImage from '../../assets/learnactiveTwitter-600x314.jpeg';
import css from './LandingPageCoachLosi.module.css';

export const LandingPageCoachLosiComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  //search form component
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPageCoachLosi.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPageCoachLosi.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      description={schemaDescription}
      contentType="website"
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
          {/* <Topbar /> */}
          {/* <OttawaContainer /> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHeroCoachLosi className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionWhyCoachLosiLanding
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
              <div className={css.sectionContentFirstChild}>
                <SectionDownloadCoachLosi />
              </div>
            </li>
            {/* <li className={css.sectionBlue}>
              <div className={css.sectionContentFirstChild}>
                <SectionChallenge />
              </div>
            </li> */}
            {/* <li className={css.sectionBlue}>
              <div className={css.sectionContentFirstChild}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li> */}
            {/* TODO Find Active Sport instructors near your place in Ottawa Section */}
            {/* <li className={css.sectionBlue}>
              <div className={css.sectionContentFirstChild}>
              <div className={css.searchContainer}> */}
            {/* {search} */}
            {/* </div>
              </div>
            </li> */}
            {/* TODO: Find your favorite Active Sport 'category' */}
            {
              // TODO: make location conditional on the section's visibility
              //! Comment till instructors have been onboarded and then make it location dependent before it shows
            }
            {/* <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionSports />
              </div>
            </li> */}
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageCoachLosiComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageCoachLosiComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // search form
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPageCoachLosi = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageCoachLosiComponent);

export default LandingPageCoachLosi;
