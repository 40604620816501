import React from 'react';
import '../ProgressStepBar/styles.scss';
import { ProgressBar, Step } from '../ProgressStepBar/index.js';
import css from './ProgressBar.module.css';
import Starter from './LevelStarter.png';
import Pro from './LevelPro.png';
import Master from './LevelMaster.png';
import Elite from './LevelElite.png';

const ProgressBarComponent = props => {
  return (
    <div className={css.bar}>
      <ProgressBar
        percent={props.number}
        stepPositions={[0, 20, 50, 100]}
        filledBackground="linear-gradient(to right, #53BCFF, #027AFE, #53BCFF)"
      >
        <Step transition="scale">
          {({ accomplished, position }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
              <svg
                className={css.img}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 100}%) brightness(${
                    accomplished ? 1 : 2
                  })`,
                }}
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#007AFF" />
              </svg>
              <h5>Starter</h5>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, position, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
              <svg
                className={css.img}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 100}%) brightness(${
                    accomplished ? 1 : 2
                  })`,
                }}
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#007AFF" />
              </svg>

              <h5>Pro</h5>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index, position }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
              <svg
                className={css.img}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 100}%) brightness(${
                    accomplished ? 1 : 2
                  })`,
                }}
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#007AFF" />
              </svg>
              <h5>Elite</h5>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, position = 83.33 }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
              <svg
                className={css.img}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 100}%) brightness(${
                    accomplished ? 1 : 2
                  })`,
                }}
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#007AFF" />
              </svg>
              <h5>Master</h5>
            </div>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

export default ProgressBarComponent;
