import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '..';
import { BookingTimeGroupForm } from '../../forms';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import css from './BookingPanelGroup.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanelGroup = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = props;

  const price = listing.attributes.price;
  const groupPrice =
    listing.attributes.publicData && listing.attributes.publicData.groupPrice
      ? listing.attributes.publicData.groupPrice
      : null;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const listingTimeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  console.log(listing.attributes.availabilityPlan);
  const listingClassDetails = listing.attributes.metadata?.class_details;
  const listingClassSize = listing.attributes.metadata?.class_size;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const hasGroupPrice = groupPrice?.amount !== undefined;
  const showGroupBookingTimeForm = hasListingState && !isClosed && hasGroupPrice;

  const showClosedListingHelpText = listing.id && isClosed;

  try {
    // Your code that may throw an error goes here
    // const febSeats = monthlyTimeSlots['2023-03']?.timeSlots[0]?.attributes?.seats;
    const keys = Object.keys(monthlyTimeSlots);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      // const seats = monthlyTimeSlots[key].timeSlots[0].attributes?.seats;
      console.log(key, monthlyTimeSlots[key]);

      const timeSlots = monthlyTimeSlots[key].timeSlots;
      // do something with timeSlots for this date
      // console.log(timeSlots);
      for (let i = 0; i < timeSlots?.length; i++) {
        let ts = timeSlots[i];
        // console.log(ts.attributes);
        // !!! CONTINUE FROM HERE
        // do something with this time slot for this date
      }
    }
    // console.log(febSeats);
  } catch (error) {
    // Handle the error here
    console.error(error);
  }

  const groupPriceObject = priceData(new Money(groupPrice?.amount, groupPrice?.currency), intl);
  const formattedGroupPrice = groupPriceObject.formattedPrice;
  const groupPriceTitle = groupPriceObject.priceTitle;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  // console.log(lineItems);
  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        {showGroupBookingTimeForm ? (
          <div className={css.bookingHeading}>
            <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={groupPriceTitle}>
                {formattedGroupPrice}
              </div>
              {/* <div className={css.desktopPerUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div> */}
            </div>
            <div className={css.bookingHeadingContainer}>
              <h2 className={titleClasses}>{title}</h2>
              {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
            </div>
          </div>
        ) : (
          <div className={css.bookingHeading}>
            <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div>
            <div className={css.bookingHeadingContainer}>
              <h2 className={titleClasses}>{title}</h2>
              {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
            </div>
          </div>
        )}
        {showGroupBookingTimeForm ? (
          showBookingTimeForm ? (
            <BookingTimeGroupForm
              className={css.bookingForm}
              formId="BookingPanelGroup"
              submitButtonWrapperClassName={css.submitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={groupPrice}
              listingId={listing.id}
              title={title}
              listingClassDetails={listingClassDetails}
              listingClassSize={listingClassSize}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              listingTimeZone={listingTimeZone}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              groupPrice={groupPrice}
            />
          ) : null
        ) : showBookingTimeForm ? (
          <BookingTimeGroupForm
            className={css.bookingForm}
            formId="BookingPanelGroup"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            title={title}
            listingClassDetails={listingClassDetails}
            listingClassSize={listingClassSize}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            listingTimeZone={listingTimeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            groupPrice={groupPrice}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanelGroup.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanelGroup.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanelGroup);
