import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import css from './PostChallenges.module.css';

class PostListChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
    this.createMarkup = this.createMarkup.bind();
  }

  async componentDidMount() {
    // const posts =
    //   this.props.return == '1'
    // ? await axios.get(
    //     `/wp-json/wp/v2/posts?categories=19&per_page=${this.props.return}&order=desc&orderby=date`
    //   )
    //     : await axios.get('/wp-json/wp/v2/posts?categories=19'); // For dev environments
    const posts =
      this.props.return != '-1'
        ? await axios.get(
            `https://blog-api.learnactive.ca/wp-json/wp/v2/posts?per_page=${this.props.return}&order=desc&orderby=date`
          )
        : await axios.get('https://blog-api.learnactive.ca/wp-json/wp/v2/posts');
    const updatedPosts = await Promise.all(
      posts.data.map(async post => {
        if (post.content.rendered) {
          let parser = new DOMParser();
          let contentHTML = parser.parseFromString(post.content.rendered, 'text/html');
          let imgElements = contentHTML.getElementsByTagName('img');

          if (imgElements.length > 0) {
            post.imageURL = imgElements[0].src; // We just grab the first one
          }
        }

        let date = new Date(post.date);
        post.date = date.toLocaleDateString('en-US', {
          month: 'long',
        });
        if (post.featured_media) {
          // const image = await axios.get(`/wp-json/wp/v2/media/${post.featured_media}`); // For dev environments
          const image = await axios.get(
            `https://blog-api.learnactive.ca/wp-json/wp/v2/media/${post.featured_media}`
          );
          post.imageURL = image.data.source_url;
        }
        // removing <p>, </p> tags from the excerpt
        let text = post.excerpt.rendered.replace(/<\/?p>/g, '');

        // Establish the limit for the excerpt length
        let maxLength = 160; // Adjust this value according to your needs

        if (text.length > maxLength) {
          // Find the last space (< maxLength)
          let trimmedText = text.substr(0, maxLength);
          trimmedText = trimmedText.substr(
            0,
            Math.min(trimmedText.length, trimmedText.lastIndexOf(' '))
          );

          // Reassign the truncated version to the excerpt
          post.excerpt.rendered = `<p>${trimmedText.trim()}…</p>`;
        }

        return post;
      })
    );

    this.setState({
      posts: updatedPosts,
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    // ? image as card background
    return (
      <>
        <div className={css.PostListChallenges}>
          {this.state.posts.map(post => (
            <Link to={`/blog/${post.slug}`} key={post.id} className={css.postLink}>
              <div className={css.cardWrapper}>
                <div
                  className={css.cardBackground}
                  style={{
                    backgroundImage: `url(${post.imageURL})`,
                    // boxShadow: `0 0 20px ${gradients[gradientIndex++ % gradients.length]}`,
                  }}
                />
                <div className={css.cardContent}>
                  <div className={css.Left}>
                    <h6 className={css.postDate}></h6>
                    <h3 className={css.postTitle}>{post.title.rendered}</h3>
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(post.excerpt.rendered)}
                      className={css.postExcerpt}
                    />
                  </div>
                  {post.imageURL && (
                    <img src={post.imageURL} alt="Post" className={css.postImage} />
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </>
    );
  }
}

export default PostListChallenges;
