import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '..';
import { PostListArticles, NamedLink } from '..';
import css from './SectionLatestArticles.module.css';

const SectionLatestArticles = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        Stay up to date
        {/* <FormattedMessage id="SectionLatestArticles.titleLineOne" />
        <br />
        <FormattedMessage id="SectionLatestArticles.titleLineTwo" /> */}
      </div>

      <p className={css.subtitle}>
        Keep up with the latest trends, tips, and insights in fitness, sports, and wellness.
      </p>
      <PostListArticles return="2" />
      <NamedLink name="BlogPage" className={css.heroButtonBlue}>
        <FormattedMessage id="SectionLatestArticles.cta" />
      </NamedLink>
    </div>
  );
};

SectionLatestArticles.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionLatestArticles.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionLatestArticles;
