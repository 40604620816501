import React, { Component } from 'react';
import { bool, node, string, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import css from './Arrow.module.css';
import { Button } from '..'
import { IconCheckmark } from '..';

class Arrow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
        };
    }
    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        const {  direction, disabled, onClick, children, className, style } = this.props;
        const { mounted } = this.state;
    

        return (
            <Button
                
                rootClassName={css.arrowClass}
         
                aria-hidden="true"
            >
             <IconCheckmark rootClassName={css.customIcon} />
                {children}
            </Button>
        );


    }
}

Arrow.defaultProps = {
    direction:null,
    disabled:false, 
    children: null,
};

Arrow.propTypes = {
   
    disabled:bool,
    onClick: func,
    children: node,
    className: string,
}

export default Arrow;