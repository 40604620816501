/*
* Renders a set of transactions obtained from api
*/


import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';

import css from './RewardsTransactions.module.css';

const Transaction = props => {
    const { date, desc, amount }  = props;
    return (
        <li className={css.item}>
            <p className={css.left}>{date}</p>
            <p className={css.center}>{desc}</p>
            <p className={css.right}>{amount}</p>
        </li>
    );
};

const RewardsTransactions = props => {
    const { className, id, transactions } = props;

    return (
       
            <ul className={css.group}>
            <h2 className={css.header}>My Transactions </h2>
            <div className={css.tableHeader}>
            <h4 className={css.tableHeaderItem}>Date</h4>
            <h4 className={css.tableHeaderItem}>Description</h4>
            <h4 className={css.tableHeaderItem}>Amount</h4>
            </div>
                {transactions.map(transaction => (
                    <Transaction key={`${id}.${transaction.key}`} date={transaction?.date?.slice(0, 9)} desc={transaction.desc} amount={transaction.amount}/>
                )).reverse()}
            
            </ul>
        
    );
    };

    RewardsTransactions.defaultProps = {
        className: null,
        id: null,
        transactions: []
        };

    const { arrayOf, bool, node, shape, string } = PropTypes;

    
    RewardsTransactions.propTypes= {
        className: string,
        id: string.isRequired,
        transactions: arrayOf(
            shape({
                date: string,
                desc: string,
                amount: string,
            })
        ),
    };

    export default RewardsTransactions;