import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '..';
import { PostListChallenges } from '../../components';
import css from './SectionChallenge.module.css';

const SectionChallenge = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        Challenge of the Month
        {/* <FormattedMessage id="SectionChallenge.titleLineOne" />
        <br />
        <FormattedMessage id="SectionChallenge.titleLineTwo" /> */}
      </div>

      <p className={css.subtitle}>
        Take your fitness journey to the next level with our challenge of the month. Designed to
        push your limits, keep you engaged, and help you achieve your health and fitness goals. 
      </p>
      <PostListChallenges return="1" />
    </div>
  );
};

SectionChallenge.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionChallenge.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionChallenge;
