import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '..';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build AddressLinkMaybe
const ClassLinkMaybe = props => {
  const { className, rootClassName, link, hasClassLink } = props;
  // const { address, building } = location || {};
  // const { lat, lng } = geolocation || {};
  // const hrefToGoogleMaps = geolocation
  //   ? `https://maps.google.com/?q=${lat},${lng}`
  //   : address
  //   ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
  //   : null;

  // const fullAddress =
  //   typeof building === 'string' && building.length > 0 ? `${building}, ${address}` : address;

  const classes = classNames(rootClassName || css.address, className);
  return hasClassLink ? (
    <>
      <h4>
        <strong>Class Join Link: </strong>
      </h4>
      <p className={classes}>
        <em>When you're set, click the link below to join your class.</em> <br></br>
        <br></br>
        <div className={css.ClassLinkMaybe}>
          <ExternalLink href={link}>{link} </ExternalLink>
        </div>
      </p>
    </>
  ) : null;
};

export default ClassLinkMaybe;
