import React, { useState } from 'react';
import { arrayOf, array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
import {
  EditListingAvailabilityPlanForm,
  EditListingGroupAvailabilityPlanForm,
  EditListingAvailabilityExceptionForm,
} from '../../forms';
const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');
import css from './EditListingAvailabilityPanel.module.css';
import { CONSOLE_LEVELS } from '@sentry/utils';
require('dotenv').config('../../../.env');
const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const integrationSdk = flexIntegrationSdk.createInstance({
  // These two env vars need to be set in the '.env' file.
  clientId: '83db4dd6-340d-4984-bdb4-1541025ca04f',
  clientSecret: 'c2446013beb87bf026747339bdd3fc73027f27fb',

  // Normally you can just skip setting the base URL and just use the
  // default that the 'createInstance' uses. We explicitly set it here
  // for local testing and development.
  baseUrl: process.env.FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
});
// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;
const listingUUID = [];
const detailStore = [];
const classSizeStore = [];
const classSizeStoreIndex = classSizeStore[0];
const detailStoreIndex = detailStore[0];

//   metadata: {
//     class_details: dowObj,
//   },
// });
const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

const createEntryDayGroups = (entries = {}) =>
  // Create initial entry mapping for form's initial values
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek, seats } = entry;
    //TODO: FIX Group Class Feature Bugs to uncomment FieldTextInput
    // const { startTime, endTime: endHour, dayOfWeek, seats, classStyle } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
          seats,
          //TODO: FIX Group Class Feature Bugs to uncomment FieldTextInput
          // classStyle,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isGroupEditPlanModalOpen, setIsGroupEditPlanModalOpen] = useState(false);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  listingUUID.push(currentListing.id.uuid);
  const details = currentListing.attributes.metadata?.class_details;
  const sizeStore = currentListing.attributes.metadata?.class_size;
  // console.log('sizeStore says', sizeStore);

  // TODO: CONTINUE FROM HERE FOR CLASS NAME IMPLEMENTATION
  // integrationSdk.listings
  //   .show({
  //     id: currentListing.id.uuid,
  //   })
  //   .then(res => {
  //     const detail = res.data.data.attributes.metadata?.//class_details; //
  //     // obj[detail];
  //     // dowObj = detail;
  //     detailStore.push(detail);
  //     console.log('detail', detailStore);
  //   });

  // Create entries from submit values
  const dowObj = details;
  const sizeObj = sizeStore;
  const createEntriesFromSubmitValues = values =>
    WEEKDAYS.reduce((allEntries, dayOfWeek) => {
      const dayValues = values[dayOfWeek] || [];
      const dayEntries = dayValues.map(dayValue => {
        // TODO implement seats entry for dynamic userseats feature
        const { startTime, endTime, seats } = dayValue;

        //TODO: FIX Group Class Feature Bugs to uncomment FieldTextInput

        // const { startTime, endTime, seats, classStyle } = dayValue;
        // console.log('dayValue', classStyle);
        // const { startTime, endTime, seats } = dayValue;

        //TODO: FIX Group Class Feature Bugs to uncomment FieldTextInput
        // details[dayOfWeek + startTime + endTime] = classStyle;

        // Note: This template doesn't support seats yet.
        // return startTime && endTime && seats

        //TODO: FIX Group Class Feature Bugs to uncomment FieldTextInput
        //   return startTime && endTime && seats && classStyle
        //     ? {
        //         dayOfWeek,
        //         seats: seats,
        //         // classStyle: classStyle,
        //         startTime,
        //         endTime: endTime === '24:00' ? '00:00' : endTime,
        //       }
        //     : null;
        // });
        return startTime && endTime && seats
          ? {
              dayOfWeek,
              seats: seats,
              // classStyle: classStyle,
              startTime,
              endTime: endTime === '24:00' ? '00:00' : endTime,
            }
          : null;
      });

      return allEntries.concat(dayEntries.filter(e => !!e));
    }, []);

  // Create availabilityPlan from submit values
  const createAvailabilityPlan = values => ({
    availabilityPlan: {
      type: 'availability-plan/time',
      timezone: values.timezone,
      entries: createEntriesFromSubmitValues(values),
    },
  });

  // Ensure that the AvailabilityExceptions are in sensible order.
  //
  // Note: if you allow fetching more than 100 exception,
  // pagination kicks in and that makes client-side sorting impossible.
  const sortExceptionsByStartTime = (a, b) => {
    return a.attributes.start.getTime() - b.attributes.start.getTime();
  };

  const Weekday = props => {
    const { availabilityPlan, dayOfWeek, openEditModal } = props;
    const hasEntry = findEntry(availabilityPlan, dayOfWeek);

    return (
      <div
        className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
        onClick={() => openEditModal(true)}
        role="button"
      >
        <div className={css.dayOfWeek}>
          <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
        </div>
        <div className={css.entries}>
          {availabilityPlan && hasEntry
            ? getEntries(availabilityPlan, dayOfWeek).map(e => {
                const detailObj = detailStore.pop() || dowObj;
                const sizeDetailObj = classSizeStore.pop() || sizeObj;

                {
                  /* const class_name = detailObj[dayOfWeek + e.startTime + e.endTime]; */
                }
                {
                  /* const class_name = currentListing.attributes.metadata?.class_details
                  ? dowObj[dayOfWeek + e?.startTime + e?.endTime]
                  : 'loading'; */
                }
                {
                  /* console.log(class_name); */
                }
                return (
                  // TODO add seats input for dynamic userseats feature
                  <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${
                    e.startTime
                  } - ${e.endTime === '00:00' ? '24:00' : e.endTime} | ${e.seats} seats `}</span>
                );
              })
            : null}
        </div>
      </div>
    );
  };
  const WeekdayForGroup = props => {
    const { availabilityPlan, dayOfWeek, openEditModal } = props;
    const hasEntry = findEntry(availabilityPlan, dayOfWeek);

    return (
      <div
        className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
        onClick={() => openEditModal(true)}
        role="button"
      >
        <div className={css.dayOfWeek}>
          <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
        </div>
        <div className={css.entries}>
          {availabilityPlan && hasEntry
            ? getEntries(availabilityPlan, dayOfWeek).map(e => {
                const detailObj = detailStore.pop() || dowObj;
                const sizeDetailObj = classSizeStore.pop() || sizeObj;
                console.log('store is ' + detailStore[0]);
                {
                  /* const class_name = detailObj[dayOfWeek + e.startTime + e.endTime]; */
                }

                //? HOW CLASS NAME IS RETRIEVED FROM THE STORE
                {
                  /* const class_name = currentListing.attributes.metadata?.class_details
                  ? dowObj[dayOfWeek + e?.startTime + e?.endTime]
                  : 'loading'; */
                }

                //? HOW CLASS SIZE IS RETRIEVED FROM THE STORE
                {
                  /* const class_size = sizeObj[dayOfWeek + e.startTime + e.endTime] || 'loading'; */
                }
                {
                  /* console.log(class_name); */
                }
                return (
                  // TODO add seats input for dynamic userseats feature " | ${class_name}"
                  <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${
                    e.startTime
                  } - ${e.endTime === '00:00' ? '24:00' : e.endTime} | ${e.seats} seats`}</span>
                );
              })
            : null}
        </div>
      </div>
    );
  };
  const isNextButtonDisabled = !currentListing.attributes.availabilityPlan;
  // !
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);
    // integrationSdk.listings.update({
    //   id: listingUUID[0],
    //   metadata: {
    //     class_details: dowObj,
    //   },
    // });
    integrationSdk.listings.update({
      id: listingUUID[0],
      metadata: {
        class_size: availabilityPlan.entries,
      },
    });
    // Final Form can wait for Promises to return.
    return onSubmit(createAvailabilityPlan(values))
      .then(() => {
        setIsEditPlanModalOpen(false);
        setIsGroupEditPlanModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime, seats } = values;

    // TODO: add proper seat handling
    // const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{
              listingTitle: (
                <ListingLink listing={listing}>
                  <FormattedMessage id="EditListingAvailabilityPanel.listingTitle" />
                </ListingLink>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      {/* <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
          </h2>
          <InlineTextButton
            className={css.editPlanButton}
            onClick={() => setIsEditPlanModalOpen(true)}
          >
            <IconEdit className={css.editPlanIcon} />{' '}
            <FormattedMessage id="EditListingAvailabilityPanel.edit" />
          </InlineTextButton>
        </header>
        <div className={css.week}>
          {WEEKDAYS.map(w => (
            <Weekday
              dayOfWeek={w}
              key={w}
              availabilityPlan={availabilityPlan}
              openEditModal={setIsEditPlanModalOpen}
            />
          ))}
        </div>
      </section> */}
      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="EditListingAvailabilityPanel.groupScheduleTitle" />
          </h2>
          <InlineTextButton
            className={css.editPlanButton}
            onClick={() => setIsGroupEditPlanModalOpen(true)}
          >
            <IconEdit className={css.editPlanIcon} />{' '}
            <FormattedMessage id="EditListingAvailabilityPanel.edit" />
          </InlineTextButton>
        </header>
        <div className={css.week}>
          {WEEKDAYS.map(w => (
            <Weekday
              dayOfWeek={w}
              key={w}
              availabilityPlan={availabilityPlan}
              openEditModal={setIsGroupEditPlanModalOpen}
            />
          ))}
        </div>
      </section>
      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            {fetchExceptionsInProgress ? (
              <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
            ) : (
              <FormattedMessage
                id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
                values={{ count: exceptionCount }}
              />
            )}
          </h2>
        </header>
        {fetchExceptionsInProgress ? (
          <div className={css.exceptionsLoading}>
            <IconSpinner />
          </div>
        ) : exceptionCount === 0 ? (
          <div className={css.noExceptions}>
            <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
          </div>
        ) : (
          <div className={css.exceptions}>
            {sortedAvailabilityExceptions.map(availabilityException => {
              const { start, end, seats } = availabilityException.attributes;
              return (
                <div key={availabilityException.id.uuid} className={css.exception}>
                  <div className={css.exceptionHeader}>
                    <div className={css.exceptionAvailability}>
                      <div
                        className={classNames(css.exceptionAvailabilityDot, {
                          [css.isAvailable]: seats > 0,
                        })}
                      />
                      <div className={css.exceptionAvailabilityStatus}>
                        {seats > 0 ? (
                          <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                        ) : (
                          <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
                        )}
                      </div>
                    </div>
                    <button
                      className={css.removeExceptionButton}
                      onClick={() =>
                        onDeleteAvailabilityException({ id: availabilityException.id })
                      }
                    >
                      <IconClose size="normal" className={css.removeIcon} />
                    </button>
                  </div>
                  <TimeRange
                    className={css.timeRange}
                    startDate={start}
                    endDate={end}
                    seats={seats}
                    dateType={DATE_TYPE_DATETIME}
                    timeZone={availabilityPlan.timezone}
                  />
                </div>
              );
            })}
          </div>
        )}
        {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
          <InlineTextButton
            className={css.addExceptionButton}
            onClick={() => setIsEditExceptionsModalOpen(true)}
            disabled={disabled}
            ready={ready}
          >
            <FormattedMessage id="EditListingAvailabilityPanel.addException" />
          </InlineTextButton>
        ) : null}
      </section>

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          onClick={onNextTab}
          disabled={isNextButtonDisabled}
        >
          {submitButtonText}
        </Button>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityPlanForm
            formId="EditListingAvailabilityPlanForm"
            listingTitle={currentListing.attributes.title}
            availabilityPlan={availabilityPlan}
            weekdays={WEEKDAYS}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
            subCategory={currentListing.attributes.publicData.sportSubCategory}
            // detailObj={dowObj}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isGroupEditPlanModalOpen}
          onClose={() => setIsGroupEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingGroupAvailabilityPlanForm
            formId="EditListingGroupAvailabilityPlanForm"
            listingTitle={currentListing.attributes.title}
            availabilityPlan={availabilityPlan}
            weekdays={WEEKDAYS}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
            subCategory={currentListing.attributes.publicData.sportSubCategory}
            // detailObj={dowObj}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
