import React from 'react';
import { bool, func, shape, string, array } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingSubFeaturesForm.module.css';

const EditListingSubFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        sportCategory,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSubFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSubFeaturesForm.showListingFailed" />
        </p>
      ) : null;

     
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {sportCategory.map((sport) => {
        const options = findOptionsForSelectFilter('sportCategory', filterConfig);
        const sportStyles = options.find((f) => f.key === sport);
        const Styles = sportStyles.styles;
        return (
          <>
            <h2>{sport} styles</h2>
            <FieldCheckboxGroup
              className={css.features}
              id={name}
              name={name}
              options={Styles}
              twoColumns={true}
            />
          </>
        );
      })}
         

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
      // }
      // )

// else if (sportCategory.length > 1) {
//   for (let i = 0; i < sportCategory.length; i++) {
//         let sport = sportCategory[i]
//     const options = findOptionsForSelectFilter('sportCategory', filterConfig);
//     const sportStyles = options.find(f => f.key === sport)
//     const Styles=sportStyles.styles
    
//     return (
//         <Form className={classes} onSubmit={handleSubmit}>
//           {errorMessage}
//           {errorMessageShowListing}

//           <FieldCheckboxGroup className={css.features} id={name} name={name} options={Styles}  twoColumns={true}/>

//           <Button
//             className={css.submitButton}
//             type="submit"
//             inProgress={submitInProgress}
//             disabled={submitDisabled}
//             ready={submitReady}
//           >
//             {saveActionMsg}
//           </Button>
//         </Form>
//       );
//   };
// }

// console.log(Styles)
// console.log(options)
    
    }}
  />
);

EditListingSubFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingSubFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  sportCategory: array.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingSubFeaturesForm = EditListingSubFeaturesFormComponent;

export default EditListingSubFeaturesForm;
