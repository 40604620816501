import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/HowLearnRewardsWorksPage/SET_INITIAL_STATE';

// ================ Reducer ================ //

const initialState = {
    userId: null,
    userListingRefs: [],
    userShowError: null,
    queryListingsError: null,
  };

    export default function howLearnRewardsWorksPageReducer(state = initialState, action = {}) {
        const { type, payload } = action;
        switch (type) {
            case SET_INITIAL_STATE:
                return { ...initialState };
            default:
                return state;
        }
    }

// ================ Action creators ================ //
export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
  });
  export const showUserRequest = userId => ({
    type: SHOW_USER_REQUEST,
    payload: { userId },
  });
  export const loadData = params => (dispatch, getState, sdk) => {
    const userId = new UUID(params.id);
  
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());
  
    return Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(showUser(userId)),
    ]);
  };