import React, { Component } from 'react';
import axios from 'axios';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  Page,
} from '../';
import { StaticPage, TopbarContainer } from '../../containers';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './Post.module.css';
import ShareComponent from './Share';
import { TailSpin } from 'react-loader-spinner';

class PostView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {
        title: {
          rendered: 'LearnActive Blog',
        },
      },
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    const slug = this.props.params.slug;

    axios.get(`https://blog-api.learnactive.ca/wp-json/wp/v2/posts?slug=${slug}`).then(post => {
      // axios.get(`/wp-json/wp/v2/posts?slug=${slug}`).then(post => {  // For dev environments

      let date = new Date(post.data[0].date);
      post.data[0].date = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (post.data[0].content.rendered) {
        let parser = new DOMParser();
        let contentHTML = parser.parseFromString(post.data[0].content.rendered, 'text/html');
        let imgElements = contentHTML.getElementsByTagName('img');

        if (imgElements.length > 0) {
          post.data[0].imageURL = imgElements[0].src; // We just grab the first one
        }
      }

      if (post.data[0].featured_media) {
        // const image = axios.get(`/wp-json/wp/v2/media/${post.featured_media}`);
        const image = axios.get(
          `https://blog-api.learnactive.ca/wp-json/wp/v2/media/${post.featured_media}`
        );
        post.data[0].imageURL = image.data.source_url;
      }

      this.setState({
        post: post.data[0],
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    console.log(this.state.post);
    let build;
    if (this.state.post.content) {
      build = (
        <div className={css.blogPage}>
          <h1 className={css.pageTitle}>{this.state.post.title.rendered}</h1>
          <article className={css.contentWrapper}>
            <div
              className={css.contentMain}
              dangerouslySetInnerHTML={this.createMarkup(this.state.post.content?.rendered)}
            />
          </article>
          <hr />
          <div className={css.postDateContainer}>
            <p className={css.postDatePreText}>Posted on </p>
            <p className={css.postDate}> {this.state.post.date}</p>
          </div>
          <ShareComponent post={this.state.post} />
        </div>
      );
    } else {
      build = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <TailSpin radius={10} color="#152192" />
        </div>
      );
    }
    const removePTags = input => {
      return input?.replace(/<\/?p[^>]*>/g, '');
    };
    let description = removePTags(this.state?.post.excerpt?.rendered);

    return (
      <StaticPage
        title={this.state?.post?.title?.rendered + ' | LearnActive'}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          description: description,
          name: 'Blog page',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>{build}</LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

export default PostView;
