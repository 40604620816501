import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //
export const UPDATE_POINT_REQUEST = 'app/ProfileSettingsPage/UPDATE_POINT_REQUEST';
export const UPDATE_POINT_SUCCESS = 'app/ProfileSettingsPage/UPDATE_POINT_SUCCESS';
export const UPDATE_POINT_ERROR = 'app/ProfileSettingsPage/UPDATE_POINT_ERROR';

// ================ Reducer ================ //

const initialState= {
    updateInProgress: false,
    updatePointError: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (typw) {
        case UPDATE_POINTS_REQUEST:
            return {
                ...state,
                updateInProgress: true,
                updatePointError: null,
            };
        case UPDATE_POINT_SUCCESS:
            return {
                ...state,
                updateInProgress: false,
            };
        
        case UPDATE_POINT_ERROR:
            return {
                ...state,
                updateInProgress: false,
                updatePointError: payload,
            };

        default:
            return state;
    }
}
// ================ Selectors ================ //

// ================ Action creators ================ //

// SDK method: sdk.currentUser.updateProfile
export const updatePointRequest = params => ({
    type: UPDATE_POINT_REQUEST,
    payload: { params },
});

export const updatePointSuccess = result => ({
    type: UPDATE_POINT_SUCCESS,
    payload: result.data,
});

export const updatePointError = error => ({
    type: UPDATE_POINT_ERROR,
    payload: error,
    error: true,
});

// ================ Thunk ================ //
export const updatePoint = actionPayload => {
    return (dispatch, getState, sdk) => {
        dispatch(updatePointRequest());

        const queryParams = {
            expand: true,
        };

        return sdk.currentUser
            .updateProfile(actionPayload, queryParams)
            .then(response => {
                dispatch(updatePointSuccess(response));

                const entities = denormalizedResponseEntities(response);
                if (entities.length !== 1) {
                    throw new Error('Expected a resource in the sdk.currentUser.updatePoint response');
                }
                const currentUser = entities[0];

                // update current user in state.user.currentUser through user.duck.js
                dispatch(currentUserShowSuccess(currentUser));   
            })
            .catch(e => dispatch(updatePointError(storableError(e))));
    };
};

