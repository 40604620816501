import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-ottawa-on',
    predictionPlace: {
      address: 'Ottawa, Ontario, CA',
      bounds: new LatLngBounds(
        new LatLng(45.505384, -75.415892),
        new LatLng(45.370961, -75.733245)
      ),
    },
  },
  {
    id: 'default-toronto-on',
    predictionPlace: {
      address: 'Toronto, Ontario, CA',
      bounds: new LatLngBounds(
        new LatLng(43.819666, -79.249094),
        new LatLng(43.637069, -79.563762)
      ),
    },
  },
  {
    id: 'default-calgary-ab',
    predictionPlace: {
      address: 'Calgary, Alberta, CA',
      bounds: new LatLngBounds(
        new LatLng(51.162122, -113.946728),
        new LatLng(51.019802, -114.09954)
      ),
    },
  },
  {
    id: 'default-edmonton-ab',
    predictionPlace: {
      address: 'Edmonton, Alberta, CA',
      bounds: new LatLngBounds(
        new LatLng(53.635682, -113.408764),
        new LatLng(53.442673, -113.594221)
      ),
    },
  },
  {
    id: 'default-winnipeg-mb',
    predictionPlace: {
      address: 'Winnipeg, Manitoba, CA',
      bounds: new LatLngBounds(
        new LatLng(49.976397, -97.092005),
        new LatLng(49.771952, -97.187457)
      ),
    },
  },
];
export default defaultLocations;
