import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '..';

import css from './SectionWhyCoachLosi.module.css';

const SectionWhyCoachLosi = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionWhyCoachLosi.titleLineOne" />
        <br />
      </div>
      <p className={css.subTitle}>
        <FormattedMessage id="SectionWhyCoachLosi.titleLineTwo" />
      </p>

      <div className={css.steps}>
        <div className={css.step}>
          <div className={css.blue}>
            <img
              id="toggleLosi"
              className={css.icon}
              src="/static/icons/personalized-guidance.svg"
              alt="Receive Personalized Guidance from Coach Losi AI"
            ></img>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionWhyCoachLosi.part1Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionWhyCoachLosi.part1Text" />
            </p>
          </div>

          <div className={css.blue}>
            <img
              id="toggleLosi"
              className={css.icon}
              src="/static/icons/access-anytime-anywhere.svg"
              alt="Access Coach Losi AI Anytime Anywhere"
            ></img>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionWhyCoachLosi.part2Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionWhyCoachLosi.part2Text" />
            </p>
          </div>
        </div>

        <div className={css.step}>
          {/* <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionWhyCoachLosi.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionWhyCoachLosi.part3Text" />
          </p> */}
          <button id="toggleLosi" className={css.toggleLosi}>
            <img
              id="toggleLosi"
              className={css.coachLosiRight}
              src="/static/icons/CoachLosiCircleAsset-cp.gif"
              alt="Coach Losi"
            ></img>
          </button>
          <button name="Losi" className={css.heroButton} id="toggleLosi">
            Ask Coach Losi
          </button>
        </div>
      </div>
      <div className={css.createListingLink}>
        <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink>
      </div>
    </div>
  );
};

SectionWhyCoachLosi.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionWhyCoachLosi.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionWhyCoachLosi;
