import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureCurrentUser, ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';
import { EditListingLocationForm, EditListingLocationOnlineForm } from '../../forms';
import config from '../../config';

import { ButtonTabNavHorizontal } from '../../components';

const selfLinkProps = {
  name: 'StyleguideComponent',
  params: { component: 'TabNavHorizontal' },
};

import css from './EditListingLocationPanel.module.css';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      name: 'ZoomConnect',
      showHideIn_person: false,
      showHideOnline: true,
      initialValues: this.getInitialValues(),
      platformConnected: false,
    };

    this.hideComponent = this.hideComponent.bind(this);
  }
  hideComponent(name) {
    switch (name) {
      case 'showHideIn_person':
        this.setState({ showHideIn_person: !this.state.showHideIn_person });
        break;
      case 'showHideOnline':
        this.setState({ showHideOnline: !this.state.showHideOnline });
        break;
      default:
        null;
    }
  }

  getInitialValues() {
    const { currentUser, listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const { geolocation, publicData } = currentListing.attributes;
    var zoomConnectionStatus = currentListing.author.attributes.profile.metadata?.zoomIntegrated;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;

    const onlineFieldsPresent = publicData && publicData.connected && publicData.platform;
    var isConnected = zoomConnectionStatus ? zoomConnectionStatus : false;
    const platform = publicData && publicData.platform ? publicData.platform : null;
    // console.log(isConnected)
    const ConnectionState = publicData && publicData.platform ? publicData.platform : null;
    // console.log(publicData)

    return {
      platform,
      connected: isConnected
        ? {
            connected: isConnected,
          }
        : null,
      building,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation },
          }
        : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      currentUser,
      disabled,
      disabledOnline,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;
    const { showHideIn_person, showHideOnline, initialValues, platformConnected } = this.state;
    const returnUrl = window.location.href;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    var zoomConnectionStatus = currentListing.author.attributes.profile.metadata?.zoomIntegrated;
    var isConnected = zoomConnectionStatus ? zoomConnectionStatus : false;
    // console.log(listing.author.id.uuid);
    const { description, title, publicData } = currentListing.attributes;
    var tab1selected = true;
    var tab2selected = false;
    const noop1 = () =>
      this.hideComponent('showHideIn_person') &
      this.hideComponent('showHideOnline') &
      (tab1selected = true) &
      (tab2selected = false) &
      this.forceUpdate();
    const noop2 = () =>
      this.hideComponent('showHideOnline') &
      this.hideComponent('showHideIn_person') &
      (tab2selected = true) &
      (tab1selected = false) &
      this.forceUpdate();
    const userEmail = currentListing.author.attributes.email;

    var linkTabs = [
      { text: 'In-person', selected: showHideIn_person, onClick: noop1 },
      { text: 'Online', selected: showHideOnline, onClick: noop2 },
      // { text: 'Hybrid', onClick: noop },
    ];
    // console.log(publicData)
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingLocationPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );
    const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
    //  console.log(publicData.connected)
    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <ButtonTabNavHorizontal className={css.ButtonTabNavHorizontal} tabs={linkTabs} />
        <br />
        {showHideIn_person && (
          <EditListingLocationForm
            className={css.form}
            initialValues={this.state.initialValues}
            onSubmit={values => {
              const { building = '', location } = values;

              const {
                selectedPlace: { address, origin },
              } = location;
              const updateValues = {
                geolocation: origin,
                publicData: {
                  location: { address, building },
                },
              };
              this.setState({
                initialValues: {
                  building,
                  location: { search: address, selectedPlace: { address, origin } },
                },
              });
              onSubmit(updateValues);
            }}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            disabled={disabled}
            ready={ready}
            updated={!showHideIn_person || panelUpdated}
            connected={platformConnected}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        )}
        {showHideOnline && (
          <EditListingLocationOnlineForm
            className={css.form}
            initialValues={this.state.initialValues}
            returnUrl={returnUrl}
            listingId={listing.author.id.uuid}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const { platform, connected, building = '', location } = values;

              const {
                selectedPlace: { address, origin },
              } = location;
              const updateValues = {
                geolocation: origin,
                publicData: {
                  location: { address, building },
                  platform: 'zoom',
                  connected: true,
                },
              };
              this.setState({
                initialValues: {
                  building,
                  location: { search: address, selectedPlace: { address, origin } },
                },
              });
              onSubmit(updateValues);
            }}
            userEmail={userEmail}
            onChange={onChange}
            disabled={publicData.connected || !publicData.connected}
            isConnected={isConnected}
            ready={ready}
            updated={platformConnected || panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
          />
        )}
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
